import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/esm/Spinner";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { RxCross2 } from "react-icons/rx";
import LanguageConvert from "./languageConvert";
const InviteBusinessPartner = ({
  businessPartner,
  loading,
  isBusinessPartner,
  setIsBusinessPartner,
  setIsBusinessPartnerModal,
  isBusinessPartnerModal,
}) => {
  const { t } = useTranslation();
  let ishbrews = localStorage.getItem("i18nextLng");
  const closeDialog = () => {
    setIsBusinessPartnerModal(false);
  };
  const baseColumns = [
    {
      name: (
        <span className="dataTable-text">{t("datatable.businesspartner")}</span>
      ),
      key: "businesspartnerName",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.businesspartnerName}
        </span>
      ),
      width: "230px",
    },
    {
      name: <span className="dataTable-text">{t("datatable.docAmount")}</span>,
      key: "vatId",
      selector: (row) => (
        <span className="text-start column-text">{row?.docAmount}</span>
      ),
    },
    {
      name: <span className="dataTable-text">{t("datatable.Status")}</span>,
      key: "docAmount",
      selector: (row) => (
        <span
          className={`text-start column-text ${
            row?.status == "Connected to Uninet"
              ? "connected-text"
              : row?.status == "Still not connected"
              ? "not-connected-text"
              : row?.status == "Waiting for invitation"
              ? "missing-text"
              : row?.status == "עדיין לא מחובר ליונינט"
              ? "not-connected-text"
              : row?.status == "מחובר ליונינט"
              ? "connected-text"
              : row?.status == "מחכה להזמנה"
              ? "missing-text"
              : "not-valid-text"
          } `}
        >
          {row?.status}
        </span>
      ),
    },
  ];
  return (
    <div>
      {isBusinessPartnerModal ? (
        <>
          <div
            id="custom-prompt"
            className="fixed  flex items-center justify-center  p-0 m-0 flex-col z-50 back-color  modal-pedding"
          >
            <div className="bg-white rounded-lg shadow-lg   p-4 border mx-2 modal-width-handle">
              <div
                className={`align-start flex justify-end items-center ${
                  ishbrews === "he" ? "flex-row-reverse" : "flex-row"
                }`}
              >
               <div className="w-11/12">
              <h1  className={
                  ishbrews === "he"
                    ? "text-text-color text-xl md:text-2xl font-bold  text-center"
                    : "text-text-color text-xl ps-md-4 md:text-1xl font-bold text-center"
                }>{t('businessPartners.yourBusinessPartners')}</h1>
              </div>

                <div
                  className={
                    ishbrews === "he"
                      ? "flex justify-start w-1/12"
                      : "flex justify-end w-1/12"
                  }
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => closeDialog()}
                  >
                    <RxCross2
                      size={"25px"}
                      className="text-text-color cursor-pointer font-normal"
                    />
                  </span>
                </div>
              </div>
              <div
                className={
                  ishbrews === "he"
                    ? "row flex-wrap flex-row-reverse pt-2"
                    : "row flex-wrap flex-row pt-2 mt-3"
                }
              >
                <div className="datatable-width-set">
                  <DataTable
                    columns={baseColumns}
                    data={businessPartner}
                    fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    noContextMenu
                    responsive={true}
                    subHeaderAlign="center"
                    direction={ishbrews == "he" ? "rtl" : "ltr"}
                    progressPending={loading}
                    progressComponent={
                      loading ? (
                        <div
                          className=" d-flex justify-content-center align-items-center"
                          style={{ height: "400px" }}
                        >
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <div
                          className=" d-flex justify-content-center align-items-center border"
                          style={{ height: "400px" }}
                        >
                          {t("datatable.noRecordsFound")}
                        </div>
                      )
                    }
                    noDataComponent={
                      loading ? (
                        <div
                          className="w-100 d-flex justify-content-center align-items-center"
                          style={{ height: "400px" }}
                        >
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <div
                          className="w-100 d-flex justify-content-center align-items-center"
                          style={{ height: "400px" }}
                        >
                          {t("datatable.noRecordsFound")}
                        </div>
                      )
                    }
                  />
                </div>
                <div
                  className={`flex justify-center mt-4 gap-3 ${
                    ishbrews === "he" ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <button
                    className="text-white font-bold text-base bg-cyan-500 rounded-md shadow-md w-52 h-11"
                    onClick={() => closeDialog()}
                  >
                    {t("businessPartners.close")}
                  </button>
                  <Link to="/main-console/business-partners">
                    <button className="text-white font-bold text-base bg-cyan-500 rounded-md shadow-md  w-52 h-11">
                      {t("main-console.businessPartners")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/*  */}
    </div>
  );
};

export default InviteBusinessPartner;
