import React, { useContext, useEffect, useRef, useState } from "react";
import LogoIcon from "../assets/images/Logo.webp";
import { RiLogoutCircleLine } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import HeaderConsole from "./headerConsole";
import businessPartner from "../assets/images/business-partner-dark.svg";
// import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import Billing from "./Billing";
import ExpenseManagment from "./expenseManagment";
import SystemSetting from "./systemSetting";
import { PiCreditCardBold, PiGearBold, PiWalletBold } from "react-icons/pi";
import BusinessDetails from "./businessDetails";
import CreditForm from "./addCard";
import BusinessPartners from "../views/businessPartners";
import { FaUsers } from "react-icons/fa";
import Api from "../services/api";
const SideBarConsole = ({ sidebarWidth, sideBarRef }) => {
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [tableLoading, setTableLoading] = useState(false);
  let ishbrews = localStorage.getItem("i18nextLng");
  const businessPartnerRecord = localStorage.getItem("BusinessPartnerRecord");
  const [totalDocs, setTotalDocs] = useState(0);
  const [showExceedsMessage, setShowExceedsMessage] = useState(false);
  const [showFirstTime, setShowFirstTime] = useState(false);
  const [billingStatus, setBillingStatus] = useState(false);
  const [billingResponse, setBillingResponse] = useState();
  const [businessPartner, setBusinessPartner] = useState(0);
  const [loading, setLoading] = useState(false);
  const { setUrl } = useContext(AuthUserContext);
  const [isBusinessPartner, setIsBusinessPartner] = useState(false);
  const [isBusinessPartnerModal,setIsBusinessPartnerModal]= useState(false)
  const [isColor, setIsColor] = useState("");
  let { path } = useRouteMatch();
  const totalDocsByLocalStoraage = localStorage.getItem("totalDocs");
  const token = localStorage?.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const SubCompanyId = localStorage?.getItem("SubCompanyId");
  
  const [subId, setSubId] = useState();
  const [bgColor, setBgColor] = useState(
    isColor === "expense" ? "#219ebc" : "#023047"
  );
  const [businessPartnerBgColors, setBusinessPartnerBgColorsBgColor] = useState(
    isColor === "business-partners" ? "#219ebc" : "#023047"
  );
  const businessRef = useRef(null);
  const divRef = useRef(null);

  const handleMouseEnter = () => {
    if (isColor !== "expense") {
      setBgColor("#219EBC");
    }
  };
  const handleMouseLeave = () => {
    if (isColor !== "expense") {
      setBgColor("#023047");
    }
  };
  const getBusinessPartner = async () => {
    try {
      if (token) {
        const lang = ishbrews === "he" ? 2 : 1;
        const subIdCheck = subId ? subId : SubCompanyId;
        if(SubCompanyId){
          const response = await Api.getBusinessPartnersByFilter(
            3,
            subIdCheck,
            1,
            5,
            lang,
            token
          );
          if (response) {
            localStorage.setItem("BusinessPartnerRecord", response.totalResults);
            setBusinessPartner(response.totalResults);
          }
        }
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (e) {
      if (e?.status === 401) {
        if (refreshToken) {
          await Api.refreshToken({
            authenticationToken: refreshToken,
          })
            .then(async (res) => {
              localStorage.setItem("accessToken", res?.accessToken);
              localStorage.setItem("refreshToken", res?.refreshToken);
              const lang = ishbrews === "he" ? 2 : 1;
              const subIdCheck = subId ? subId : SubCompanyId;
               if(subIdCheck){
                await Api.getBusinessPartnersByFilter(
                  3,
                  subIdCheck,
                  1,
                  5,
                  lang,
                  res?.accessToken
                )
                  .then((res) => {
                    if (res) {
                      setBusinessPartner(res.totalResults);
                      localStorage.setItem(
                        "BusinessPartnerRecord",
                        res.totalResults
                      );
                    }
                  })
                  .catch((err) => {
                    toast.error(err?.message ?? err);
                  });
               }
            })
            .catch((err) => {
              if (err?.status === 400) {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            });
        } else {
          toast.error(t("token.authentication-message"));
          history.push("/");
        }
      } else {
        toast.error(e?.message ?? e);
      }
    }
  };
  const handleBusinessPartnerMouseEnter = () => {
    if (isColor !== "business-partners") {
      setBusinessPartnerBgColorsBgColor("#219EBC");
    }
  };
  const handleBusinessPartnerMouseLeave = () => {
    if (isColor !== "business-partners") {
      setBusinessPartnerBgColorsBgColor("#023047");
    }
  };

  const [subCompanyList, setSubCompanyList] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [subComId, setSubComId] = useState();
  const { pathname } = useLocation();

  const changeRoute = () => {
    try {
      if (pathname) {
        if (pathname === "/main-console/expense") {
          setIsColor("expense");
        } else if (pathname === "/main-console/business-partners") {
          setIsColor("business-partners");
        } else if (pathname === "/main-console/billing") {
          setIsColor("billing");
        } else if (pathname === "/main-console/setting") {
          setIsColor("setting");
        }
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    setBgColor(isColor === "expense" ? "#219ebc" : "#023047");
    setBusinessPartnerBgColorsBgColor(
      isColor === "business-partners" ? "#219ebc" : "#023047"
    );
  }, [isColor]);
  useEffect(() => {
    changeRoute();
  }, [pathname]);
  useEffect(() => {
    let subCompanysGetByLocalStorage = [];
  
  try {
    const subCompanysFromLocalStorage = localStorage.getItem("SubCompanys");
    // Check if value is not null or undefined and parse it safely
    if (subCompanysFromLocalStorage && subCompanysFromLocalStorage !== "undefined") {
      subCompanysGetByLocalStorage = JSON.parse(subCompanysFromLocalStorage);
    }
  } catch (error) {
    console.error("Failed to parse SubCompanys from localStorage:", error);
    subCompanysGetByLocalStorage = []; // Fallback to empty array
  }
      const subCompanyCheck =
      subCompanyList.length > 0 ? subCompanyList : subCompanysGetByLocalStorage;
    setSubCompanyList(subCompanyCheck);
    subCompanyCheck?.map((element) => {
      if (!subId && element.isDefault) {
        setSubComId(element.subCopmanyId);
        setShowFirstTime(element.showFirstTimeMessage);
        setShowExceedsMessage(element.showExceedsMessage);
      }
      if (element.subCopmanyId == subId) {
        setShowFirstTime(element.showFirstTimeMessage);
        setShowExceedsMessage(element.showExceedsMessage);
      }
    });
    getBusinessPartner();
  }, [subId, isLoginSuccess,SubCompanyId]);

  useEffect(() => {
    let isBusinessPartnerRecord = localStorage.getItem("BusinessPartnerRecord");
    if (isBusinessPartnerRecord) {
      setBusinessPartner(isBusinessPartnerRecord);
    }
  }, []);
  const handlecredit = () => {
    setBillingStatus(false);
    if (
      billingResponse.title == "Billing Succeed" ||
      billingResponse.title == "החיוב הצליח"
    ) {
      setShowExceedsMessage(false);
      setShowFirstTime(false);
    }
  };

  return (
    <>
      <div className="w-full flex">
        <div
          ref={sideBarRef}
          className={
            ishbrews === "he"
              ? "min-h-screen hidden md:block fixed px-3 right-0 shadow-md bg-white border-r-2 border-r-[#D8D8D8] z-50"
              : "min-h-screen hidden md:block fixed px-3 shadow-md bg-white border-r-2 left-0 border-r-[#D8D8D8] z-50"
          }
        >
          <div className="flex flex-col">
            <div className="logo border-b-2 border-b-[#D8D8D8] py-[7px]">
              <img
                src={LogoIcon}
                alt="logo"
                className="h-auto max-w-[65px] mx-auto cursor-pointer"
              />
            </div>
            <div className="flex flex-col justify-between h-[87vh]">
              <ul className="mt-4 flex flex-col">
                <li
                  className={`flex flex-col items-center gap-1 ${
                    isColor === "expense"
                      ? "text-[#219ebc]"
                      : "text-[#023047] hover:text-[#219ebc]"
                  }`}
                >
                  <Link
                    to="/main-console/expense"
                    className="flex flex-col items-center gap-1"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span>
                      <PiWalletBold size={"25px"} />
                    </span>
                    <div className="relative inline-block">
                      <span className="text-[13px] font-semibold text-center">
                        {t("main-console.header-text")}
                      </span>
                      <span
                        ref={divRef}
                        style={{ backgroundColor: bgColor }}
                        className={`absolute top-[-10px] text-[10px] font-semibold text-white rounded-full min-w-[20px] flex items-center justify-center ${
                          ishbrews === "he" ? "left-0" : "right-0"
                        }`}
                      >
                        {totalDocs ? totalDocs : totalDocsByLocalStoraage}
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  className={`flex flex-col items-center gap-1 mt-4 ${
                    isColor === "business-partners"
                      ? " text-[#219ebc] "
                      : "text-[#023047] hover:text-[#219ebc]"
                  }`}
                >
                  <Link
                    to="/main-console/business-partners"
                    className="flex flex-col items-center gap-1 "
                    onMouseEnter={handleBusinessPartnerMouseEnter}
                    onMouseLeave={handleBusinessPartnerMouseLeave}
                  >
                    <span>
                      {/* <img src={businessPartner} width={25}/> */}
                      <FaUsers size={"25px"} />
                    </span>
                    <div className="relative inline-block">
                      <span className="text-[13px] font-semibold text-center">
                        {t("main-console.businessPartners")}
                      </span>
                      <span
                        ref={businessRef}
                        style={{ backgroundColor: businessPartnerBgColors }}
                        className={`absolute top-[-10px] text-[10px] font-semibold text-white rounded-full min-w-[20px] flex items-center justify-center ${
                          ishbrews === "he" ? "left-4" : "right-8"
                        }`}
                      >
                        {businessPartner
                          ? businessPartner
                          : businessPartnerRecord}
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>

              <ul className="flex flex-col gap-4">
                <li
                  className={`flex flex-col items-center gap-1 ${
                    isColor === "billing"
                      ? " text-[#219ebc] "
                      : "text-[#023047] hover:text-[#219ebc]"
                  }`}
                >
                  <Link
                    to="/main-console/billing"
                    className="flex flex-col items-center gap-1"
                  >
                    <span>
                      <PiCreditCardBold size={"25px"} />
                    </span>

                    <span className="text-[13px] font-semibold text-center">
                      {t("billing.title")}
                    </span>
                  </Link>
                </li>
                <li
                  className={`flex flex-col items-center gap-1 ${
                    isColor === "setting"
                      ? " text-[#219ebc] "
                      : "text-[#023047] hover:text-[#219ebc]"
                  }`}
                >
                  <Link
                    to="/main-console/setting"
                    className="flex flex-col items-center gap-1 "
                  >
                    <span>
                      <PiGearBold size={"25px"} />
                    </span>
                    <span className="text-[13px] font-semibold text-center">
                      {t("system-setting.title")}
                    </span>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    localStorage.clear();
                    toast.success(t("Signup.outScuccess"));
                    history.push("/");
                    setUrl("");
                  }}
                >
                  {ishbrews === "en" ? (
                    <div className="flex flex-col items-center gap-1 text-text-color cursor-pointer hover:text-bg-secondary">
                      <span>
                        <RiLogoutCircleLine size={"20px"} />
                      </span>
                      <span className="text-[13px] font-semibold ">
                        {t("main-console.logout")}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center gap-1 text-text-color cursor-pointer hover:text-bg-secondary">
                      <span>
                        <AiOutlineLogout size={"20px"} />
                      </span>
                      <span className="text-[13px] font-semibold ">
                        {t("main-console.logout")}
                      </span>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <HeaderConsole
          sidebarWidth={sidebarWidth}
          isLoginSuccess={isLoginSuccess}
          setTotalDocs={setTotalDocs}
          setSubId={setSubId}
          subCompanyList={subCompanyList}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <div
          className={
            ishbrews === "he"
              ? " flex w-full flex-col md:flex-row-reverse gap-2  pt-16 md:pt-20 pb-3 px-2 bg-primary-color  h-full  mt-auto mr-auto mb-0 "
              : // : " flex w-full flex-col md:flex-row gap-2 md:w-[calc(100%-100px)] pt-16 md:pt-20 pb-3 px-2 bg-primary-color  h-full  mt-auto ml-auto mb-0 "
                " flex flex-col md:flex-row gap-2 pt-16 md:pt-20 pb-3 px-2 bg-primary-color h-full mt-auto mb-0 w-full "
          }
        >
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/expense`} />
            </Route>
            <Route
              path={`${path}/expense`}
              render={(props) => (
                <ExpenseManagment
                  {...props}
                  sidebarWidth={sidebarWidth}
                  setIsLoginSuccess={setIsLoginSuccess}
                  setTotalDocs={setTotalDocs}
                  subId={subId}
                  totalDocs={totalDocs}
                  setSubCompanyList={setSubCompanyList}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  isBusinessPartner={isBusinessPartner}
                  setIsBusinessPartner={setIsBusinessPartner}
                  setIsBusinessPartnerModal={setIsBusinessPartnerModal}
                  isBusinessPartnerModal={isBusinessPartnerModal}
                />
              )}
            />
            <Route
              path={`${path}/business-partners`}
              render={(props) => (
                <BusinessPartners
                  {...props}
                  sidebarWidth={sidebarWidth}
                  setBusinessPartner={setBusinessPartner}
                  subId={subId}
                  refresh={refresh}
                  setTableLoading={setTableLoading}
                  tableLoading={tableLoading}
                />
              )}
            />
            <Route
              path={`${path}/billing`}
              render={(props) => (
                <Billing
                  {...props}
                  sidebarWidth={sidebarWidth}
                  subId={subId}
                  refresh={refresh}
                />
              )}
            />

            <Route
              path={`${path}/setting`}
              render={(props) => (
                <SystemSetting
                  {...props}
                  sidebarWidth={sidebarWidth}
                  subId={subId}
                  refresh={refresh}
                />
              )}
            />
            <Route
              path={`${path}/newentity`}
              render={(props) => (
                <BusinessDetails refresh={refresh} sidebar={sidebarWidth} />
              )}
            />
          </Switch>
        </div>

        {/* <ToastContainer rtl={ishbrews === "he" ? true : false} /> */}
      </div>
      {showExceedsMessage && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-100 mt-4 md:mx-0 back-color"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[720px] p-3 max-h-[80vh] overflow-y-auto">
            <h1
              className={
                "text-2xl text-center text-text-color mb-2 mt-1 font-medium"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("billing.exceedsTitle")}
            </h1>

            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-lg mb-1"
                  : "text-gray-800 text-lg mb-1"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("billing.exceedsContent")}
            </p>
            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-lg mb-3"
                  : "text-gray-800 text-lg mb-3"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("billing.exceedsContent1")}
            </p>
            <hr class="border-black-500 border-t-2" />
            <CreditForm
              showExceedsMessage={showExceedsMessage}
              setLoading={setLoading}
              setBillingResponse={setBillingResponse}
              setBillingStatus={setBillingStatus}
              refresh={refresh}
              subId={subId ? subId : subComId}
            />
          </div>
        </div>
      )}
      {showFirstTime && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-100 mx-2 mt-4 md:mx-0 back-color "
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[600px] p-3 max-h-[80vh] overflow-y-auto">
            <h2
              className={`text-2xl font-semibold text-black-500 text-center mb-2`}
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("billing.noPayment")}
            </h2>
            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-lg mb-1"
                  : "text-gray-800 text-lg mb-1"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("billing.content")}
            </p>
            <hr class="border-black border-t-2" />
            <CreditForm
              showFirstTime={showFirstTime}
              setShowFirstTime={setShowFirstTime}
              setLoading={setLoading}
              setBillingResponse={setBillingResponse}
              setBillingStatus={setBillingStatus}
              refresh={refresh}
              subId={subId ? subId : subComId}
            />
          </div>
        </div>
      )}
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {billingStatus && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-100 mx-2 md:mx-0 back-color"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[400px] p-5 ">
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            ></div>
            <h1
              className={
                "text-2xl text-center text-text-color mb-2 font-medium mb-4"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {billingResponse.title}
            </h1>

            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-sm mb-4"
                  : "text-gray-800 text-sm mb-4"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {billingResponse.content}
            </p>
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <button
                className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                onClick={handlecredit}
              >
                {billingResponse.buttonLabel}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBarConsole;
