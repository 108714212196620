import React, { useContext, useEffect, useState } from "react";
import NeedHelp from "../components/needHelp";
import { useHistory } from "react-router-dom";
import { PiHandshakeLight } from "react-icons/pi";
import Spinner from "react-bootstrap/Spinner";
import Navbars from "./navbar/navbar";
import { useTranslation } from "react-i18next";
import Api from "../services/api";
import { toast ,ToastContainer} from "react-toastify";
import { TokenExpired } from "../services/tokenExpired";
import { AuthUserContext } from "../context";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const WelcomeScreen = () => {
  const { t } = useTranslation();
  let ishbrews = localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [message, setMessage] = useState("");
    const { setTokenExpired } = useContext(AuthUserContext);
  // const handleWhatsAppButtonClick = () => {
  //   const whatsappUrl = "https://api.whatsapp.com/send?phone=972584222456";
  //   window.open(whatsappUrl, "_blank");
  // };
  const [entity, setEntity] = useState([]);
    useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
      const lang = ishbrews === "he" ? 2 : 1;
      
    if (token) {
      Api.getWelcomeToUninet(lang, token)
        .then(async (res) => {
          setMessage(res);
          setEntity(res.EntityDetail.split(','));

      })
     .catch(async (err) => {
        if (err?.status === 401) {
          if (refreshToken) {
            await Api.refreshToken({
              authenticationToken: refreshToken,
            }).then(async(res) => {
              localStorage.setItem("accessToken", res?.accessToken);
              localStorage.setItem("refreshToken", res?.refreshToken);
              const expiredSecond = await TokenExpired.getExpiredMinutes(res?.RefreshTokenExpiredTime);
              
              await Api.getWelcomeToUninet(lang, res?.accessToken)
                .then(async (res) => {
                setMessage(res);
                  setEntity(res.EntityDetail.split(','));
                  setLoading(false);
              })
                .catch(async (e) => {
                toast.error(e?.message ?? e);
              });
            }).catch((err) => {
                setLoading(false);
              if (err?.status === 400) {
                history.push("/");
                toast.error(t("token.authentication-message"));
              }
            });
          } else {
            setLoading(false);
            toast.error(t("token.authentication-message"));
            history.push("/");
          }
        } else {
      
          toast.error(err?.message ?? err);
        }
      })
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
    }, [])
  
  const handleSubmit = async () => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    setLoading(true)
    if (token) {
      Api.inviteBusinessPartners(
        ishbrews === "he" ? 2 : 1,
        token
      ).then((response) => {
        
        if (response.success === true) {
          localStorage.setItem("userFlag", true);
          toast.success(response?.textResponse);
          history.push('/main-console')
        }
        setLoading(false)
      }).catch(async (err) => {
        if (err?.status === 401) {
          if (refreshToken) {
            await Api.refreshToken({
              authenticationToken: refreshToken,
            }).then(async(res) => {
              localStorage.setItem("accessToken", res?.accessToken);
              localStorage.setItem("refreshToken", res?.refreshToken);              
              await Api.inviteBusinessPartners(
                ishbrews === "he" ? 2 : 1,
                res?.accessToken
              ).then((secondResponse) => {
                if (secondResponse === true) {
                  localStorage.setItem("userFlag", true);
                  history.push('/main-console')
                }
                setLoading(false)
              })
              .catch(async (e) => {
                toast.error(e?.message ?? e);
                setLoading(false)
              });
            }).catch((err) => {
              setLoading(false)
              if (err?.status === 400) {
                history.push("/");
                toast.error(t("token.authentication-message"));
              }
            });
          } else {
            setLoading(false)
            toast.error(t("token.authentication-message"));
            history.push("/");
          }
        } else {
          setLoading(false)
          toast.error(err?.message ?? err);
        }
      })
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
      setLoading(false);
    }


  }
  return (
    <>
      { !message && (
            <div className="loader-overlay">
            <div className="loader"></div>
            </div>
                )}
      <div className="bg-bg-linear">
        <div className=" flex justify-center min-h-screen items-center">
          <div className="welcome-content max-w-[600px] text-center mx-2 px-2 md:mx-0">
            <div className="text-center">
              <span>
                <PiHandshakeLight
                  size={"90px"}
                  className="text-white mx-auto"
                />
              </span>
            </div>
        
            <h1 className="text-3xl md:text-[40px] font-semibold mb-4 tracking-wide  text-text-color leading-normal" style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}>
              {message.Name} &nbsp;
              </h1>
            <p className=" text-xl md:text-3xl font-normal text-text-color  mb-[25px] " style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}>
                {message.Subtitle}
            </p>
            
              {entity && entity.map((item, index) => (
          <p key={index} className="text-xl md:text-3xl font-normal text-text-color mb-[20px]" style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}>
          {item}
            </p>
            ))}
            
            
           <Link to="/main-console/business-partners">
           <button
              className=" py-2 px-4  mt-2 mb-3 text-sm font-medium text-white border-none rounded-md md:text-lg bg-text-color secondary-btn"
              // onClick={handleSubmit}
            >
              {t("welcome-screen.invitation-button")}
              {/* {loading ? (
                <>
                    {ishbrews == "he" ?
                      <>
                  <span className="">...{t("signin.Loading")}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className=" mr-1"
                      /></span>
                      </> : <>
                  <span className="">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className=" mr-1"
                    />
                    {t("signin.Loading")}...</span></>
                    }
                  </>
              ) : (
                <></>
              )} */}

            </button></Link> 
          
            <button
              className=" py-2 px-4 ms-2 ms-md-3 mb-3 text-sm font-medium text-white border-none rounded-md md:text-lg bg-text-color secondary-btn"
              onClick={() => {
                localStorage.setItem("userFlag", true);
                history.push("/main-console");
              }}
              disabled={loading}
            >
              {t("welcome-screen.go-to-console-button")}
            </button>
          </div>
        </div>
        <Navbars />
        <NeedHelp />
        {/* <ToastContainer rtl={ishbrews === "he" ? true : false}/> */}
      </div>
    </>
  );
};

export default WelcomeScreen;
