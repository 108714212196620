import React, { useEffect, useState, useContext } from "react";
import { BiSolidUserCircle, BiSync } from "react-icons/bi";
import { RiLogoutCircleLine } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context"
import { RxCross2 } from "react-icons/rx";

const HeaderConsole = ({ sidebarWidth, isLoginSuccess, setTotalDocs, setSubId, subCompanyList, refresh, setRefresh }) => {
  const [companies, setCompanies] = useState([])
  const [selectedSubCompanyId, setSelectedSubCompanyId] = useState();

  useEffect(() => {
    if (subCompanyList) {
      const a = subCompanyList;
      setCompanies(a);
    }
  }, [isLoginSuccess, subCompanyList])

  useEffect(() => {

    if (companies?.length) {
      const defaultCompany = companies?.find(company => company?.isDefault);
      setSelectedSubCompanyId(defaultCompany?.subCopmanyId);

    }
  }, [companies])

  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [newEntity, setNewEntity] = useState(false);
  const { setUrl, setIsExpiredToken } = useContext(AuthUserContext);
  let ishbrews = localStorage.getItem("i18nextLng");
  const history = useHistory();
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const containerMediaQueryStyle = {
    width: '100%',
    margin: '0',
  };

  const containerStyle = {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: ishbrews === 'he' ? `${sidebarWidth}px` : '',
    marginLeft: ishbrews === 'he' ? '' : `${sidebarWidth}px`,
  };



  const addNewEntity = () => {
    setNewEntity(false)

    history.push({
      pathname: '/main-console/newentity',
      state: { "sidebarWidth": sidebarWidth }
    });
  }

  const handleSelectChange = (event) => {
    if (event.target.value === "+ Add New Entity" || event.target.value === "+ הוספת ישות חדשה") {
      setNewEntity(true);
    }
    else {
      const companies1 = JSON.parse(localStorage.getItem("SubCompanys"));
      const comp = companies1.find(c => c.subCopmanyId == event.target.value);
      localStorage.setItem("SubCompanyId", event.target.value)
      setSelectedSubCompanyId(event.target.value);
      setSubId(parseInt(event.target.value));
      localStorage.setItem("totalDocs", comp?.totalDocs);
      setTotalDocs(comp?.totalDocs)
    }
  };
  return (
    <div
      style={isScreenSmall ? containerMediaQueryStyle : containerStyle}
      className={
        ishbrews === "he"
          ? "h-[60px]  md:h-[70px] w-full  bg-white shadow-md fixed z-50 flex flex-row-reverse justify-between items-center inset-0 border-1 mr-auto border-b-[#D8D8D8] px-[10px]"
          : "h-[60px] bg-white shadow-md fixed flex justify-between items-center z-50 inset-0 border-1 border-b-[#D8D8D8] px-[10px]  "
      }
    >
      {newEntity && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-50 mx-2 md:mx-0 back-color"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[400px] p-3 ">
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <span className="cursor-pointer" onClick={() => setNewEntity(false)}>
                <RxCross2
                  size={"30px"}
                  className="text-text-color cursor-pointer font-normal"
                />
              </span>
            </div>
            <h1
              className={"text-2xl text-center text-text-color mb-2 font-medium mb-4"}
            >
              {t("main-console.createNewEntity")}
            </h1>

            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-lg mb-4"
                  : "text-gray-800 text-lg mb-4"
              }
              style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}
            >
              {t("main-console.createContent")}
            </p>
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <button
                className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                onClick={addNewEntity}
              >

                {t("main-console.yes")}
              </button>
              <button
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                onClick={() => setNewEntity(false)}
              >
                {t("main-console.no")}
              </button>
            </div>
          </div>
        </div>
      )}
      <ul className="flex items-center space-x-2">
        <li
          className={
            ishbrews === "he"
              ? "flex items-center flex-row-reverse gap-2 text-text-color"
              : "flex flex-row items-center gap-2 text-text-color"
          }
        >
          <span>
            <BiSolidUserCircle
              size={"35px"}
              className="font-normal text-text-color"
            />
          </span>

          <span>

            <select
              value={selectedSubCompanyId}
              onChange={handleSelectChange}
              className={`p-1`}
              style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}
            >
              {companies?.length > 0 &&
                companies.map((company) => (
                  <option key={company.subCompanyId} value={company.subCopmanyId}>
                    {company.companyName}
                  </option>
                ))}

              <option className={` bg-[#023047] text-white p-2 text-xs md:text-base rounded-md text-center`}>

                <button
                  style={{ textAlign: "center" }}>
                  + {t("main-console.addNewEntity")}
                </button>
              </option>
            </select>

          </span>
          <button onClick={() => { setRefresh(refresh + 1) }}>
            <BiSync
              size={"35px"}
              className="font-normal text-text-color hover:text-bg-secondary" />
          </button>
        </li>
      </ul>

      <ul
        className="block md:hidden"
        onClick={() => {
          localStorage.clear();
          toast.success(t("Signup.outScuccess"));
          history.push("/");
          setUrl('');
          setIsExpiredToken(false);
        }}
      >
        <li>
          {ishbrews === "en" ? (
            <div className="flex flex-row items-center gap-1 text-text-color cursor-pointer hover:text-bg-secondary">
              <span>
                <AiOutlineLogout size={"20px"} />
              </span>
              <span className="text-[13px] font-semibold ">Logout</span>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-1 text-text-color cursor-pointer hover:text-bg-secondary">
              <span>
                <RiLogoutCircleLine size={"20px"} />
              </span>
              <span className="text-[13px] font-semibold ">Logout</span>
            </div>
          )}
        </li>
      </ul>
      {/* <div className="flex-grow text-center pr-14">
        <h1 className="hidden md:block md:text-3xl font-bold text-primary-color py-4 bg-gradient-to-r from-bg-secondary to-text-color text-transparent bg-clip-text">
          Main Console
        </h1>
      </div> */}
    </div>
  );
};

export default HeaderConsole;
