import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoutes } from "./private";
import authRoutes from "./authentication";
import PrivateRoute from "./private/route";
import { ToastContainer, toast } from "react-toastify";
import { AuthUserContext } from "../context";
import MainConsole from "../views/mainConsole";
import ExpiredToken from "../components/tokenExpired";
export default function MainRoute() {

  const { taostValue } = useContext(AuthUserContext);
  

  return (
    <div>
      <ToastContainer rtl={taostValue === "he" ? true : false}/> 
      {/* <Router> */}
        <ExpiredToken/>
      <Switch>
        {authRoutes.map((item, index) => (
          <Route
            key={index}
            exact
            path={item.path}
            component={item.component}
          />
        ))}

        {PrivateRoutes.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            exact
            render={() => (
              <PrivateRoute
                title={item.title}
                component={item.component}
                route={item.path}
              />
            )}
          />
        ))}
          <Route path="/main-console" component={MainConsole} />
      </Switch>
    {/* </Router> */}
    </div>
   
  );
}
