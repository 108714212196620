import React, { useContext } from "react";
import en from "../assets/images/en.svg";
import he from "../assets/images/he.svg";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context";
const LanguageConvert = ({bgKey}) => {
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    const { setTaostValue } = useContext(AuthUserContext);
  return (
    <div>
      <div className={`col-md-12 d-flex gap-3 ${bgKey && "bg-primary-color py-3"}  justify-center pb-2`}>
        <div
          className="d-flex gap-2 cursor-pointer hover:bg-[#cfcfd792] p-2"
          onClick={() => {
            changeLanguage("en");
            setTaostValue("en");
          }}
        >
          <img src={en} alt="en" />
          English
        </div>
        <div
          className="d-flex gap-2 cursor-pointer p-2 hover:bg-[#cfcfd792]"
          onClick={() => {
            changeLanguage("he");
            setTaostValue("he");
          }}
        >
          <img src={he} alt="he" />
          עברית (Hebrew)
        </div>
      </div>
    </div>
  );
};

export default LanguageConvert;
