import React from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Spinner from "react-bootstrap/Spinner";
const InviteModal = ({
  modalShow,
  setModalShow,
  setEmail,
  email,
  emailError,
  setEmailError,
  handleInvite,
  singleRecord,
  setLoadingRow
}) => {
  let ishbrews = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const closeDialog = () => {
    setModalShow(false);
    setEmailError(false);
    setEmail("");
    setLoadingRow(null)
  };
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleModal = () => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError(true);
      return;
    } else if (!emailPattern.test(email)) {
      setEmailError(true);
      return;
    }
    handleInvite(singleRecord);
    setModalShow(false);
  };
  return (
    <div>
      {modalShow && (
        <div
          id="custom-prompt"
          className="fixed  flex items-center justify-center  p-0 m-0 flex-col z-50 back-color  "
        >
          <div className="bg-white rounded-lg shadow-lg w-4/12  p-4 border mx-2 md:mx-0">
            <div
              className={`align-start flex justify-between items-center ${
                ishbrews === "he" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <div className="w-11/12">
              <h1  className={
                  ishbrews === "he"
                    ? "text-text-color text-xl md:text-2xl font-bold  text-center"
                    : "text-text-color text-xl md:text-1xl  font-bold text-center"
                }>{t('businessPartners.enterEmail')}</h1>
              </div>
              
              <div
                className={
                  ishbrews === "he" ? "flex justify-start" : "flex justify-end"
                }
              >
                <span className="cursor-pointer" onClick={() => closeDialog()}>
                  <RxCross2
                    size={"25px"}
                    className="text-text-color cursor-pointer font-normal"
                  />
                </span>
              </div>
            </div>
            <h1
                className={
                  ishbrews === "he"
                    ? "text-text-color text-xl md:text-xl mb-1 mt-3 text-right"
                    : "text-text-color text-xl md:text-xl mb-1 mt-3"
                }
              >
                {t("businessPartners.ForSending")}
              </h1>
            <div
              className={
                ishbrews === "he"
                  ? "row flex-wrap flex-row-reverse pt-2"
                  : "row flex-wrap flex-row pt-2"
              }
            >
              <div className="col-md-12 col-sm-12 mt-2">
                <input
                  className={
                    ishbrews === "he"
                      ? "Amount-input-data  w-full  px-2 text-xs  py-[12px] md:text-base text-right font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]"
                      : "Amount-input-data  w-full  px-2 text-xs  py-[12px] md:text-base font-normal leading-normal text-text-color   bg-white border border-solid appearance-none rounded-md border-[#D8D8D8]"
                  }
                  type="email"
                  name="new_expense_text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("businessPartners.enterEmail")}
                />
                {emailError && !email && (
                  <span
                    className={
                      ishbrews === "he"
                        ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                        : "text-red-600 text-sm "
                    }
                  >
                    {t("businessPartners.emailIsRequired")}
                  </span>
                )}
                {emailError && email && !emailPattern.test(email) && (
                  <span
                    className={
                      ishbrews === "he"
                        ? "text-red-600 text-sm flex justify-end items-center mt-1 "
                        : "text-red-600 text-sm "
                    }
                  >
                    {t("businessPartners.invalidEmailFormat")}
                  </span>
                )}
              </div>
            </div>
            <div
              className={
                ishbrews === "he"
                  ? "row flex-wrap flex-row-reverse pt-4 justify-center"
                  : "row flex-wrap flex-row pt-4 justify-center"
              }
              style={{ rowGap: "20px" }}
            >
              <div className="col-md-6 col-sm-12">
                <button
                  className="bg-bg-secondary py-[11px] w-full text-white text-xs md:text-base rounded-md"
                  onClick={handleModal}
                >
                  {t("system-setting.update")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteModal;
