import React, { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../context";
import { TokenExpired } from "../services/tokenExpired";
import { t } from "i18next";
import Api from "../services/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function ExpiredToken() {
  const { isExpiredToken, setTokenExpired, setIsExpiredToken } =
    useContext(AuthUserContext);
  const [expiredToken, setExpiredToken] = useState(false);
  const ishbrews = localStorage.getItem("i18nextLng");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    if (isExpiredToken) {
      setExpiredToken(true); setIsExpiredToken(true);
    }
  }, [isExpiredToken, setIsExpiredToken]);

  useEffect(() => {
    if (isExpiredToken && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isExpiredToken, timer]);

  useEffect(() => {
    if (timer == 0) {
      logout();
    }
  }, [timer]);

  useEffect(() => {
    setExpiredToken(isExpiredToken);
  }, [isExpiredToken, setIsExpiredToken]);

  const extend = async () => {
    setLoading(true);
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      await Api.refreshToken({
        authenticationToken: refreshToken,
      })
        .then(async (res) => {
          localStorage.setItem("accessToken", res?.accessToken);
          localStorage.setItem("refreshToken", res?.refreshToken);
          const expiredSecond = TokenExpired.getExpiredMinutes(
            res?.RefreshTokenExpiredTime
          );
          localStorage.setItem("refershTokenExpired", expiredSecond);
          setTokenExpired(expiredSecond);
          setExpiredToken(false);
          setIsExpiredToken(false);
          setLoading(false);
          setTimer(120);
        })
        .catch((err) => {
          setLoading(false);
          setExpiredToken(false);
          setTimer(120);
          setIsExpiredToken(false);
          toast.error(t("token.authentication-message"));
          history.push("/");
        });
    } else {
      setLoading(false);
      setExpiredToken(false);
      setTimer(120);
      setIsExpiredToken(false);
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  };
  const logout = () => {
    setTimer(120);
    setExpiredToken(false);
    setIsExpiredToken(false);
    setTokenExpired(null);
    localStorage.clear();
    history.push("/");
    toast.success(t("Signup.outScuccess"));
  };
  return (
    <div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {expiredToken && (
        <div className="w-full md:w-1/4 p-0 bg-white rounded-md shadow-md  h-auto md:h-full ">
          <div
            id="custom-prompt"
            className="fixed flex items-center justify-center flex-col z-50 mx-2 md:mx-0 back-color"
          >
            <div className="bg-white rounded-lg shadow-lg max-w-[500px] p-4 ">
              <h1
                className={
                  "text-2xl text-center text-text-color mb-3 font-medium mb-4 mt-2"
                }
                style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
              >
                {t("token.tokenExTitle")}
              </h1>
              <p
                className={
                  ishbrews === "he"
                    ? "text-gray-800 text-right font-normal mb-4"
                    : "text-gray-800 font-normal mb-4"
                }
                style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
              >
                {t("token.tokenExContent1")} <b> {timer} </b>{" "}
                {t("token.tokenExContent2")} &quot;{t("token.extend")}&quot;{" "}
                {t("token.button")}
              </p>
              <div
                className={
                  ishbrews === "he" ? "flex justify-start" : "flex justify-end"
                }
              >
                <button
                  className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                  onClick={extend}
                >
                  {t("token.extend") + " (" + timer + ")"}
                </button>
                <button
                  className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                  onClick={logout}
                >
                  {t("main-console.logout")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
