import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./assets/css/index.css";
import "../src/assets/css/tailwind.css";
import { BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
let ishbrews = localStorage.getItem("i18nextLng");

root.render(
 
  
  <Router>
    {/* <ToastContainer rtl={ishbrews === "he" ? true : false}/> */}
    <App />
  </Router>
);
reportWebVitals();
