import React, { useEffect, useState, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { GoInbox } from "react-icons/go";
import { BsFillBookmarkXFill } from "react-icons/bs";
import { AiTwotoneEnvironment } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Api from "../services/api";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { BiLink } from "react-icons/bi";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context";
import request from "../services/requests";
import { RxCross2 } from "react-icons/rx";
import { TokenExpired } from "../services/tokenExpired";
import Pagination from "./pagination";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const TabsInfo = ({
  setPdfFile,
  setFormValues,
  setRejectData,
  setIsPDFLoaded,
  isRejected,
  setIsRejected,
  isEntered,
  setIsEntered,
  activeKey,
  setActiveKey,
  setRejectedListByUser,
  rejectedListByUser,
  setIsLoginSuccess,
  setTotalDocs,
  subId,
  totalDocs,
  setSubCompanyList,
  refresh,
  setClientId,
  setRefresh,
  businessPartner,
  setBusinessPartner,
  setIsBusinessPartner,
  isBusinessPartner,
  setIsBusinessPartnerModal
}) => {
  const { setUrl, setTokenExpired } = useContext(AuthUserContext);
  const [inboxListByUser, setInboxListByUser] = useState([]);
  const [approveListByUser, setApproveListByUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUrl = window.location.href;
  setUrl(currentUrl);
  const [inboxLoading, setInboxLoading] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(true);
  const [enteredLoading, setEnteredLoading] = useState(true);
  const [toastError, setToastError] = useState(false);
  const [toastErrorInbox, setToastErrorInbox] = useState(false);
  const [toastEntered, setToastEntered] = useState(false);
  const [toastEntered2, setToastEntered2] = useState(false);
  const [toastRejected, setToastRejected] = useState(false);
  const [toastRejected2, setToastRejected2] = useState(false);
  const [paramsDataSave, setParamsDataSave] = useState([]);
  const [tabActive, setTabActive] = useState("");
  const [enteredStopLoading, setEnteredStopLoading] = useState(false);
  const [rejectedStopLoading, setRejectedStopLoading] = useState(false);
  const [inboxStopLoading, setInboxStopLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pagePerRecord, setPagePerRecord] = useState(10);
  const query = new URLSearchParams(window.location.search);
  const jsonDocumentid = query.get("jsonDocumentid");
  const [tempId, setTempId] = useState();
  const [totalRecord, setTotalRecord] = useState();
  const [errorConnection, setErrorConnection] = useState(false);
  const [subCopmanyIds, setCompanyIds] = useState();
  const [activeIndexes, setActiveIndexes] = useState({
    Inbox: -1,
    Entered: -1,
    Rejected: -1,
  });

  const history = useHistory();
  const { t } = useTranslation();
  let ishbrews = localStorage.getItem("i18nextLng");
  const mainCompanyId = localStorage.getItem("mainCompanyId");
  const subCopmanyId = subId ? subId : localStorage.getItem("SubCompanyId");
  const token = localStorage?.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  const handleWhatsAppButtonClick = () => {
    const whatsappWebUrl = "https://web.whatsapp.com/";
    window.open(whatsappWebUrl, "_blank");
  };
  const handleSubmit = async (item, index, tabKey) => {
    if (!item) {
      setFormValues({
        supplier_ID: "",
        amountAV: "",
        internalCompanyId: "",
        docNumber: "",
        doctype: "",
        docDate: "",
        supplier_name_Sender: "",
        taxId: "",
        amountBeforeVat: "",
        vat: "",
      });
    }
    setIsPDFLoaded(true);
    setActiveIndexes(() => ({
      [tabKey]: index,
    }));
    setPdfFile(item);
    const clientVatId = item?.clientVat_id ? item.clientVat_id.toString() : "";
    setClientId(clientVatId);
    const data = {
      JsonDocumentid: item?.jsonDocumentid,
      sendingDigitalDocumentBusinessID: item?.sendingDigitalDocumentBusinessID,
      BusinessVatId: item?.businessVatId,
      ClientVat_id: clientVatId,
      Lang: ishbrews === "he" ? 2 : 1,
    };

    try {
      if (token) {
        setRejectData({
          BusinessVatId: item?.businessVatId,
          ClientVat_id: item?.clientVat_id.toString(),
          JsonDocumentid: item?.jsonDocumentid,
        });
        await Api.ShowDigitalDocumentDetails(data, token)
          .then((response) => {
            if (response) {
              localStorage.setItem("supplier_ID", response?.supplier_ID);
              setFormValues(response);
              setRejectedStopLoading(true);
              setEnteredStopLoading(true);
              setInboxStopLoading(true);
              setIsPDFLoaded(false);
            } else {
              setErrorConnection(true);
            }
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.ShowDigitalDocumentDetails(data, res?.accessToken)
                      .then((res) => {
                        localStorage.setItem("supplier_ID", res?.supplier_ID);
                        if (res) {
                          setFormValues(res);
                          setIsPDFLoaded(false);
                        } else {
                          setErrorConnection(true);
                        }
                      })
                      .catch((err) => {
                        toast.error(err?.message ?? err);
                        // history.push("/");
                        setIsPDFLoaded(false);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                      setIsPDFLoaded(false);
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
                setIsPDFLoaded(false);
              }
            } else {
              toast.error(e?.message ?? e);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
        setIsPDFLoaded(false);
      }
    } catch (e) {
      console.log("e", e);
      setIsPDFLoaded(false);
    }
  };
  const handleQueryParams = async (item, index, tabKey) => {
    setIsPDFLoaded(true);
    setActiveIndexes(() => ({
      [tabKey]: index,
    }));
    setPdfFile(item);
    const clientVatId = item?.clientVat_id ? item.clientVat_id.toString() : "";
    setClientId(clientVatId);

    const data = {
      JsonDocumentid: item?.jsonDocumentid,
      sendingDigitalDocumentBusinessID: item?.sendingDigitalDocumentBusinessID,
      BusinessVatId: item?.businessVatId,
      ClientVat_id: clientVatId,
      Lang: ishbrews === "he" ? 2 : 1,
    };

    try {
      if (token) {
        setRejectData({
          BusinessVatId: item?.businessVatId,
          ClientVat_id: item?.clientVat_id.toString(),
          JsonDocumentid: item?.jsonDocumentid,
        });
        await Api.ShowDigitalDocumentDetails(data, token)
          .then((response) => {
            if (response) {
              localStorage.setItem("supplier_ID", response?.supplier_ID);
              setFormValues(response);
            } else {
              setErrorConnection(true);
            }
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.ShowDigitalDocumentDetails(data, res?.accessToken)
                      .then((res) => {
                        localStorage.setItem("supplier_ID", res?.supplier_ID);
                        if (res) {
                          setFormValues(res);
                        } else {
                          setErrorConnection(true);
                        }
                      })
                      .catch((err) => {
                        toast.error(err?.message ?? err);
                        // history.push("/");
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            } else {
              toast.error(e?.message ?? e);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const fetchInboxData = async () => {
    try {
      setInboxLoading(true);
      setInboxListByUser([])
      setBusinessPartner([])
      setIsBusinessPartner(false)
      setIsBusinessPartnerModal(false)
      if (token) {
        const header = subId
          ? "notApproveOrRejected&subCompanyId=" +
            subId +
            "&pageNumber=" +
            selectedPage +
            "&pageSize=" +
            pagePerRecord
          : "notApproveOrRejected&pageNumber=" +
            selectedPage +
            "&pageSize=" +
            pagePerRecord;
        const lang = ishbrews === "he" ? 2 : 1;
        await Api.GetDigitalDocumentToApproveListByUser(token, header)
          .then(async (res) => {
            
            localStorage.setItem("UserName", res?.fullname);
            localStorage.setItem(
              "SubCompanys",
              JSON.stringify(res?.listOfSubCompaniesandNames)
            );
            setSubCompanyList(res?.listOfSubCompaniesandNames);
            localStorage.setItem(
              "mainCompanyId",
              res?.listOfSubCompaniesandNames[0].mainCompanyId
            );
            const SubCompanyId = res.listOfSubCompaniesandNames.find(
              (item) => item.isDefault === true
            );
            localStorage.setItem("SubCompanyId", SubCompanyId.subCopmanyId);
            setTempId(SubCompanyId.subCopmanyId);
            localStorage.setItem("totalDocs", SubCompanyId.totalDocs);
            setTotalDocs(SubCompanyId.totalDocs);
            setIsLoginSuccess(true);
            if (res) {
              if (query.size > 0) {
                if (res?.listDigitalDocumentToApprove.length > 0) {
                  let found = false;
                  res?.listDigitalDocumentToApprove.forEach((element) => {
                    if (element.jsonDocumentid === jsonDocumentid) {
                      setParamsDataSave(element);
                      found = true;
                    }
                  });
                }
                setToastError(true);
              }
              setIsPDFLoaded(false);

              if (res?.listDigitalDocumentToApprove?.length > 0) {
                setInboxListByUser(res?.listDigitalDocumentToApprove);
                setInboxLoading(false);
              } else {
                if (
                  SubCompanyId.clickedButtonToInviteBusinessPartnersSubCompany ==
                  true
                ) {
                  setInboxListByUser([])
                  const response =
                    await Api.shortVersionGetBusinessPartnersByFilter(
                      SubCompanyId.subCopmanyId,
                      ishbrews === "he" ? 2 : 1,
                      token
                    );
                  setBusinessPartner(response?.businessPartnerProp);
                  setIsBusinessPartner(true)
                  setIsBusinessPartnerModal(true)
                  setInboxLoading(false);
                } else {
                  setCompanyIds(SubCompanyId.subCopmanyId);
                  setInboxLoading(false);
                }
              }
              handleSubmit(res?.listDigitalDocumentToApprove?.[0], 0, "Inbox");
            }
            if (res?.length === 0) {
              setIsPDFLoaded(false);
              setInboxLoading(false);
              return;
            }
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    const header = subId
                      ? "notApproveOrRejected&subCompanyId=" +
                        subId +
                        "&pageNumber=" +
                        selectedPage +
                        "&pageSize=" +
                        pagePerRecord
                      : "notApproveOrRejected&pageNumber=" +
                        selectedPage +
                        "&pageSize=" +
                        pagePerRecord;
                    await Api.GetDigitalDocumentToApproveListByUser(
                      res?.accessToken,
                      header
                    )
                      .then(async(res) => {
                        if (res) {
                          localStorage.setItem(
                            "SubCompanys",
                            JSON.stringify(res?.listOfSubCompaniesandNames)
                          );
                          console.log("res?.listOfSubCompaniesandNames", res?.listOfSubCompaniesandNames);
                          setSubCompanyList(res?.listOfSubCompaniesandNames);
                          localStorage.setItem(
                            "mainCompanyId",
                            res?.listOfSubCompaniesandNames[0].mainCompanyId
                          );
                          const SubCompanyId =
                            res.listOfSubCompaniesandNames.find(
                              (item) => item.isDefault === true
                            );
                          localStorage.setItem(
                            "SubCompanyId",
                            SubCompanyId.subCopmanyId
                          );
                          setTempId(SubCompanyId.subCopmanyId);
                          localStorage.setItem(
                            "totalDocs",
                            SubCompanyId.totalDocs
                          );
                          setTotalDocs(SubCompanyId.totalDocs);
                          setIsLoginSuccess(true);
                          if (query.size > 0) {
                            let found = false;
                            if (res?.listDigitalDocumentToApprove.length > 0) {
                              res?.listDigitalDocumentToApprove.forEach(
                                (element) => {
                                  if (
                                    element.jsonDocumentid === jsonDocumentid
                                  ) {
                                    setParamsDataSave(element);
                                    found = true;
                                  }
                                }
                              );
                            }
                            setToastErrorInbox(true);
                          }
                          if (res?.listDigitalDocumentToApprove?.length > 0) {
                            setInboxListByUser(res?.listDigitalDocumentToApprove);
                            setInboxLoading(false);
                          } else {
                            if (
                              SubCompanyId.clickedButtonToInviteBusinessPartnersSubCompany ==
                              true
                            ) {
                              setInboxListByUser([])
                              const response =
                                await Api.shortVersionGetBusinessPartnersByFilter(
                                  SubCompanyId.subCopmanyId,
                                  ishbrews === "he" ? 2 : 1,
                                  res?.accessToken
                                );
                              setBusinessPartner(response?.businessPartnerProp);
                              setIsBusinessPartner(true)
                              setIsBusinessPartnerModal(true)
                              setInboxLoading(false);
                            } else {
                              setCompanyIds(SubCompanyId.subCopmanyId);
                              setInboxLoading(false);
                            }
                          }
                          setIsPDFLoaded(false);
                          // setInboxListByUser(res);
                          // setInboxLoading(false);
                        }
                        if (res?.length === 0) {
                          setIsPDFLoaded(false);
                          return;
                        }
                      })
                      .catch((err) => {
                        if (err.status === 401) {
                          toast.error(t("token.authentication-message"));
                          history.push("/");
                        }
                        toast.error(err?.message ?? err);
                        setInboxLoading(false);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            } else {
              console.log(e);

              toast.error(e?.message ?? e);
              setInboxLoading(false);
              setIsPDFLoaded(false);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (err) {
      console.error(err);
      setInboxLoading(false);
    }
  };
  const fetchEnteredData = async () => {
    try {
      setEnteredLoading(true);
      setBusinessPartner([])
      setIsBusinessPartner(false)
      setIsBusinessPartnerModal(false)
      if (token) {
        const header = subId
          ? "Approved&subCompanyId=" +
            subId +
            "&pageNumber=" +
            selectedPage +
            "&pageSize=" +
            pagePerRecord
          : "Approved&pageNumber=" +
            selectedPage +
            "&pageSize=" +
            pagePerRecord;
        await Api.GetDigitalDocumentToApproveListByUser(token, header)
          .then(async(res) => {
            if (res) {
              const SubCompanyId = res.listOfSubCompaniesandNames.find(
                (item) => item.isDefault === true
              );
              res?.listOfSubCompaniesandNames.map((element) => {
                const a = subId ? subId : tempId;
                if (element.subCopmanyId == a) {
                  setTotalRecord(element.totalDocsAccepted);
                }
              });
              if (query.size > 0 && tabActive === activeKey) {
                let found = false;
                if (res?.listDigitalDocumentToApprove.length > 0) {
                  res?.listDigitalDocumentToApprove.forEach((element) => {
                    if (element.jsonDocumentid === jsonDocumentid) {
                      setParamsDataSave(element);
                      found = true;
                    }
                  });
                }
                setToastEntered(true);
              }
              setIsPDFLoaded(false);
              if (res?.listDigitalDocumentToApprove?.length > 0) {
                setApproveListByUser(res?.listDigitalDocumentToApprove);
                setEnteredLoading(false);
              } else {
                if (
                  SubCompanyId.clickedButtonToInviteBusinessPartnersSubCompany ==
                  true
                ) {
                  setApproveListByUser([])
                  const response =
                    await Api.shortVersionGetBusinessPartnersByFilter(
                      SubCompanyId.subCopmanyId,
                      ishbrews === "he" ? 2 : 1,
                      token
                    );
                  setBusinessPartner(response?.businessPartnerProp);
                  setIsBusinessPartner(true)
                  setIsBusinessPartnerModal(true)
                  setEnteredLoading(false);
                } else {
                  setCompanyIds(SubCompanyId.subCopmanyId);
                  setEnteredLoading(false);
                }
              }
              // setApproveListByUser(res?.listDigitalDocumentToApprove);
              // setEnteredLoading(false);
              handleSubmit(res?.listDigitalDocumentToApprove[0], 0, "Entered");
            }
            if (res?.length === 0) {
              setIsPDFLoaded(false);
              return;
            }
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    const header = subId
                      ? "Approved&subCompanyId=" +
                        subId +
                        "&pageNumber=" +
                        selectedPage +
                        "&pageSize=" +
                        pagePerRecord
                      : "Approved&pageNumber=" +
                        selectedPage +
                        "&pageSize=" +
                        pagePerRecord;
                    await Api.GetDigitalDocumentToApproveListByUser(
                      res?.accessToken,
                      header
                    )
                      .then(async(res) => {
                        const SubCompanyId = res.listOfSubCompaniesandNames.find(
                          (item) => item.isDefault === true
                        );
                        if (res) {
                          res?.listOfSubCompaniesandNames.map((element) => {
                            const a = subId ? subId : tempId;
                            if (element.subCopmanyId == a) {
                              setTotalRecord(element.totalDocsAccepted);
                            }
                          });
                          if (query.size > 0 && tabActive === activeKey) {
                            let found = false;
                            if (res?.listDigitalDocumentToApprove.length > 0) {
                              res?.listDigitalDocumentToApprove.forEach(
                                (element) => {
                                  if (
                                    element.jsonDocumentid === jsonDocumentid
                                  ) {
                                    setParamsDataSave(element);
                                    found = true;
                                  }
                                }
                              );
                              if (!found) {
                              }
                            } else {
                              if (toastEntered2 === false) {
                              }
                            }
                            setToastEntered2(true);
                          }
                          if (res?.listDigitalDocumentToApprove?.length > 0) {
                            setApproveListByUser(res?.listDigitalDocumentToApprove);
                            setEnteredLoading(false);
                          } else {
                            if (
                              SubCompanyId.clickedButtonToInviteBusinessPartnersSubCompany ==
                              true
                            ) {
                              setApproveListByUser([])
                              const response =
                                await Api.shortVersionGetBusinessPartnersByFilter(
                                  SubCompanyId.subCopmanyId,
                                  ishbrews === "he" ? 2 : 1,
                                  res?.accessToken
                                );
                              setBusinessPartner(response?.businessPartnerProp);
                              setIsBusinessPartner(true)
                              setIsBusinessPartnerModal(true)
                              setEnteredLoading(false);
                            } else {
                              setCompanyIds(SubCompanyId.subCopmanyId);
                              setEnteredLoading(false);
                            }
                          }
                          // setApproveListByUser(res);
                          // setEnteredLoading(false);
                        }
                        if (res?.length === 0) {
                          setIsPDFLoaded(false);
                          return;
                        }
                        setEnteredLoading(false);
                      })
                      .catch((err) => {
                        toast.error(err?.message ?? err);
                        setEnteredLoading(false);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      // toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            } else {
              toast.error(e?.message ?? e);
              setEnteredLoading(false);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (err) {
      console.error(err);
      setEnteredLoading(false);
    }
  };
  const fetchRejectedData = async () => {
    try {
      setRejectLoader(true);
      setBusinessPartner([])
      setIsBusinessPartner(false)
      setIsBusinessPartnerModal(false)
      if (token) {
        const header = subId
          ? "Rejected&subCompanyId=" +
            subId +
            "&pageNumber=" +
            selectedPage +
            "&pageSize=" +
            pagePerRecord
          : "Rejected&pageNumber=" +
            selectedPage +
            "&pageSize=" +
            pagePerRecord;
        await Api.GetDigitalDocumentToApproveListByUser(token, header)
          .then(async(res) => {
            const SubCompanyId = res.listOfSubCompaniesandNames.find(
              (item) => item.isDefault === true
            );
            if (res) {
              res?.listOfSubCompaniesandNames.map((element) => {
                const a = subId ? subId : tempId;
                if (element.subCopmanyId == a) {
                  setTotalRecord(element.totalDocsRejected);
                }
              });
              if (query.size > 0 && tabActive === activeKey) {
                let found = false;
                if (res?.listDigitalDocumentToApprove.length > 0) {
                  res?.listDigitalDocumentToApprove.forEach((element) => {
                    if (element.jsonDocumentid === jsonDocumentid) {
                      setParamsDataSave(element);
                      found = true;
                    }
                  });
                  if (!found) {
                  }
                } else {
                  if (toastRejected === false) {
                  }
                }
                setToastRejected(true);
              }
              if (res?.listDigitalDocumentToApprove?.length > 0) {
                setRejectedListByUser(res?.listDigitalDocumentToApprove);
                setRejectLoader(false);
              } else {
                if (
                  SubCompanyId.clickedButtonToInviteBusinessPartnersSubCompany ==
                  true
                ) {
                  setRejectedListByUser([])
                  const response =
                    await Api.shortVersionGetBusinessPartnersByFilter(
                      SubCompanyId.subCopmanyId,
                      ishbrews === "he" ? 2 : 1,
                      token
                    );
                  setBusinessPartner(response?.businessPartnerProp);
                  setIsBusinessPartner(true)
                  setIsBusinessPartnerModal(true)
                  setRejectLoader(false);
                } else {
                  setCompanyIds(SubCompanyId.subCopmanyId);
                  setRejectLoader(false);
                }
              }
              handleSubmit(res?.listDigitalDocumentToApprove[0], 0, "Rejected");
              // setRejectedListByUser(res?.listDigitalDocumentToApprove);
              // setRejectLoader(false);
            }
            if (res?.length === 0) {
              setIsPDFLoaded(false);
              return;
            }
          })
          .catch(async (e) => {
            if (e?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    const header = subId
                      ? "Rejected&subCompanyId=" +
                        subId +
                        "&pageNumber=" +
                        selectedPage +
                        "&pageSize=" +
                        pagePerRecord
                      : "Rejected&pageNumber=" +
                        selectedPage +
                        "&pageSize=" +
                        pagePerRecord;
                    await Api.GetDigitalDocumentToApproveListByUser(
                      res?.accessToken,
                      header
                    )
                      .then(async(res) => {
                        const SubCompanyId = res.listOfSubCompaniesandNames.find(
                          (item) => item.isDefault === true
                        );
                        if (res) {
                          res?.listOfSubCompaniesandNames.map((element) => {
                            const a = subId ? subId : tempId;
                            if (element.subCopmanyId == a) {
                              setTotalRecord(element.totalDocsRejected);
                            }
                          });
                          if (query.size > 0) {
                            let found = false;
                            if (res?.listDigitalDocumentToApprove.length > 0) {
                              res?.listDigitalDocumentToApprove.forEach(
                                (element) => {
                                  if (
                                    element.jsonDocumentid === jsonDocumentid
                                  ) {
                                    setParamsDataSave(element);
                                    found = true;
                                  }
                                }
                              );
                              if (!found) {
                                // toast.error(t("main-console.RecordNotFound"));
                              }
                            } else {
                              if (toastRejected2 === false) {
                                // toast.error(t("main-console.RecordNotFound"))
                              }
                            }
                            setToastRejected2(true);
                          }
                          if (res?.listDigitalDocumentToApprove?.length > 0) {
                            setRejectedListByUser(res?.listDigitalDocumentToApprove);
                            setRejectLoader(false);
                          } else {
                            if (
                              SubCompanyId.clickedButtonToInviteBusinessPartnersSubCompany ==
                              true
                            ) {
                              setRejectedListByUser([])
                              const response =
                                await Api.shortVersionGetBusinessPartnersByFilter(
                                  SubCompanyId.subCopmanyId,
                                  ishbrews === "he" ? 2 : 1,
                                  res?.accessToken
                                );
                              setBusinessPartner(response?.businessPartnerProp);
                              setIsBusinessPartner(true)
                              setIsBusinessPartnerModal(true)
                              setRejectLoader(false);
                            } else {
                              setCompanyIds(SubCompanyId.subCopmanyId);
                              setRejectLoader(false);
                            }
                          }
                          // setRejectedListByUser(res);
                          // setRejectLoader(false);
                        }
                        if (res?.length === 0) {
                          setIsPDFLoaded(false);
                          return;
                        }
                      })
                      .catch((err) => {
                        if (err.status === 401) {
                          // toast.error(t("token.authentication-message"));
                          history.push("/");
                        }
                        toast.error(err?.message ?? err);
                        setRejectLoader(false);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      // toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            } else {
              toast.error(e?.message ?? e);
              setRejectLoader(false);
            }
          });
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } catch (err) {
      console.error(err);
      setRejectLoader(false);
    }
  };

  const handleSubmitInvite = async () => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    setLoading(true);
    if (token) {
      Api.inviteBusinessPartners(
        ishbrews === "he" ? 2 : 1,
        subCopmanyIds,
        token
      )
        .then((response) => {
          toast.success(response?.textResponse);
          setLoading(false);
        })
        .catch(async (err) => {
          if (err?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  const expiredSecond = await TokenExpired.getExpiredMinutes(
                    res?.RefreshTokenExpiredTime
                  );
                  setTokenExpired(expiredSecond);
                  await Api.inviteBusinessPartners(
                    ishbrews === "he" ? 2 : 1,
                    res?.accessToken
                  )
                    .then((secondResponse) => {
                      if (secondResponse === true) {
                        toast.success(res?.textResponse);
                      }
                      setLoading(false);
                    })
                    .catch(async (e) => {
                      if (err.status === 401) {
                        toast.error(t("token.authentication-message"));
                        history.push("/");
                      }
                      toast.error(e?.message ?? e);
                      setLoading(false);
                    });
                })
                .catch((err) => {
                  setLoading(false);
                  if (err?.status === 400) {
                    history.push("/");
                  }
                });
            } else {
              setLoading(false);
              toast.error(t("token.authentication-message"));
              history.push("/");
            }
          } else {
            setLoading(false);
            toast.error(err?.message ?? err);
          }
        });
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (activeKeys) {
  //     setActiveKey(activeKeys);
  //   }
  // }, []);
  useEffect(() => {
    if (activeKey === "Inbox") fetchInboxData();
    if (activeKey === "Entered") fetchEnteredData();
    if (activeKey === "Rejected") fetchRejectedData();
  }, [activeKey, selectedPage, refresh, pagePerRecord]);

  useEffect(() => {
    if (tempId && tempId !== subId) {
      setTempId(subId);
      if (selectedPage == 1 && pagePerRecord == 10) {
        setRefresh(refresh + 1);
      } else {
        setSelectedPage(1);
        setPagePerRecord(10);
      }
    }
  }, [subId]);

  const handleTabSelect = (key) => {
    setActiveKey(key);
    setSelectedPage(1);
    setPagePerRecord(10);
    setIsPDFLoaded(false);
  };

  // const getEmailGiudVerification = async () => {

  //   const data = {
  //     EmailGuidVerification: emailGuidVerification,
  //     Lang: ishbrews === "he" ? 2 : 1,
  //   }
  //   await Api.verifyEmailLink(data).then((response) => {
  //     // console.log("response: ",  response?.accessToken);
  //     if(response?.Success === true) {
  //       // localStorage.setItem("accessToken", response?.accessToken)
  //       // if(response?.accessToken === token){
  //       //      console.log("token matched");
  //       // } else{
  //       //   console.log("token not matched");
  //       // }
  //     }
  //   }).catch((error)=>{
  //     // console.log(error);
  //   })
  // }
  // useEffect(()=>{
  //   getEmailGiudVerification()
  // },[])
  const getActiveTab = async () => {
    let data = {
      jsonDocumentId: jsonDocumentid,
      Lang: ishbrews === "he" ? 2 : 1,
    };
    if (query.size > 0) {
      await Api.getActiveTab(data, token)
        .then((response) => {
          if (response.activeTabNaem) {
            setActiveKey(response?.activeTabNaem);
            setTabActive(response?.activeTabNaem);
          }
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  };

  useEffect(() => {
    getActiveTab();
  }, []);

  useEffect(() => {
    if (query.size < 0) {
      fetchInboxData();
    }
  }, []);
  useEffect(() => {
    if (query.size > 0) {
      if (tabActive === "Inbox") {
        if (inboxStopLoading === false) {
          const indexWithValueInbox = inboxListByUser.findIndex(
            (element) =>
              element?.jsonDocumentid === paramsDataSave?.jsonDocumentid
          );
          handleSubmit(paramsDataSave, indexWithValueInbox, tabActive);
        }
      } else if (tabActive === "Entered") {
        if (enteredStopLoading === false) {
          let indexWithValueEntered;
          indexWithValueEntered = approveListByUser.findIndex(
            (element) =>
              element?.jsonDocumentid === paramsDataSave?.jsonDocumentid
          );
          handleSubmit(paramsDataSave, indexWithValueEntered, tabActive);
        }
      } else {
        if (rejectedStopLoading === false) {
          const indexWithValue9 = rejectedListByUser.findIndex(
            (element) =>
              element?.jsonDocumentid === paramsDataSave?.jsonDocumentid
          );
          handleSubmit(paramsDataSave, indexWithValue9, tabActive);
        }
      }
    } else {
      if (
        inboxListByUser?.length > 0 &&
        activeIndexes?.Inbox !== 0 &&
        !approveListByUser?.length > 0 &&
        !rejectedListByUser?.length > 0
      ) {
        handleSubmit(inboxListByUser?.[0], 0, "Inbox");
      }
    }
  }, [inboxListByUser, paramsDataSave]);

  // useEffect(() => {
  //   if (
  //     inboxListByUser?.length > 0 &&
  //     activeIndexes?.Inbox != 0 &&
  //     !approveListByUser?.length > 0 &&
  //     !rejectedListByUser?.length > 0
  //   )
  //   if(query.size > 0){
  //          handleSubmit(paramsDataSave, 0, activeKeys)
  //   }else{
  //     handleSubmit(inboxListByUser?.[0], 0, "Inbox");
  //   }

  // }, [inboxListByUser]);

  useEffect(() => {
    if (isRejected === true) {
      const newArray = inboxListByUser.filter(
        (item, index) => index !== activeIndexes?.Inbox
      );
      if (activeKey === "Inbox") {
        setTotalDocs(totalDocs - 1);
        handleSubmit(newArray?.[0], 0, "Inbox");
        if (inboxListByUser.length == 1 && totalDocs > inboxListByUser.length) {
          if (selectedPage == 1) {
            setRefresh(refresh + 1);
          } else {
            setSelectedPage(1);
            setPagePerRecord(pagePerRecord);
          }
        }
      }
      setInboxListByUser(newArray);
      setIsRejected(false);
    }

    if (isEntered === true) {
      const newArray = inboxListByUser.filter(
        (item, index) => index !== activeIndexes?.Inbox
      );
      if (activeKey === "Inbox") {
        setTotalDocs(totalDocs - 1);
        handleSubmit(newArray?.[0], 0, "Inbox");
        if (inboxListByUser.length == 1 && totalDocs > inboxListByUser.length) {
          if (selectedPage == 1) {
            setRefresh(refresh + 1);
          } else {
            setSelectedPage(1);
            setPagePerRecord(pagePerRecord);
          }
        }
      }
      if (activeKey === "Rejected") {
        setTotalRecord(totalRecord - 1);
        if (
          rejectedListByUser.length == 0 &&
          totalRecord > rejectedListByUser.length &&
          totalRecord > 0
        ) {
          if (selectedPage == 1) {
            setRefresh(refresh + 1);
          } else {
            setSelectedPage(1);
            setPagePerRecord(pagePerRecord);
          }
        }
        if (!rejectedListByUser.length) setFormValues({});
        handleSubmit(rejectedListByUser[0], 0, "Rejected");
      }
      setInboxListByUser(newArray);
      setIsEntered(false);
    }
  }, [isRejected, isEntered]);

  const updateSystem = () => {
    setErrorConnection(false);
    history.push("/main-console/setting");
  };
  const handlePageChange = (page, perPage) => {
    setSelectedPage(page);
    setPagePerRecord(perPage);
  };

  return (
    <div className="w-full md:w-1/4 p-0 bg-white rounded-md shadow-md  h-auto md:h-full ">
      {errorConnection && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-50 mx-2 md:mx-0 back-color"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[400px] p-3 ">
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <span
                className="cursor-pointer"
                onClick={() => setErrorConnection(false)}
              >
                <RxCross2
                  size={"30px"}
                  className="text-text-color cursor-pointer font-normal"
                />
              </span>
            </div>
            <h1
              className={
                "text-1xl text-center text-text-color mb-2 font-medium mb-4 mt-2"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("main-console.errorInConnection")}
            </h1>

            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-lg mb-4"
                  : "text-gray-800 text-lg mb-4"
              }
              style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
            >
              {t("main-console.errorContent")}
            </p>
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <button
                onClick={updateSystem}
                className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
              >
                {t("main-console.updateSystem")}
              </button>
              <button
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                onClick={() => setErrorConnection(false)}
              >
                {t("main-console.cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
      <Tabs
        // defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        onSelect={handleTabSelect}
        activeKey={activeKey}
        className={
          ishbrews === "he"
            ? "hebrew flex-row-reverse custom-tabs"
            : "custom-tabs "
        }
      >
        <Tab
          eventKey="Inbox"
          title={t("main-console.part65")}
          className={ishbrews === "he" ? "custom-tab" : "custom-tab"}
        >
          <div className="custom-scrollbar api-scroll">
            {inboxLoading === true ? (
              <div className="border text-center text-black h-full flex justify-center items-center">
                <>
                  {ishbrews == "he" ? (
                    <>
                      <span className="">
                        ...{t("signin.Loading")}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="">
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                        {t("signin.Loading")}...
                      </span>
                    </>
                  )}
                </>
              </div>
            ) : inboxListByUser?.length > 0 ? (
              <>
                <ul className="links-list">
                  {inboxListByUser?.map((item, index) => {
                    const isActive = index === activeIndexes?.Inbox;
                    return (
                      <li
                        className={` custom-paragraph flex items-center gap-2 cursor-pointer tab-text text-[13px] font-medium leading-9  text-black hover:underline hover:text-primary-color
                         ${isActive ? "active" : ""}
                          ${ishbrews === "he" ? "flex-row-reverse" : ""}`}
                        onClick={() => handleSubmit(item, index, "Inbox")}
                      >
                        <BiLink />
                        {/* {ishbrews === "en" ? index + 1 + ". " : ""} */}
                        {ishbrews === "en"
                          ? item?.supplier_name_Sender +
                            " " +
                            `(${item?.docDate.slice(0, 10)})` +
                            "-" +
                            item?.amountAV +
                            " " +
                            item?.currency_code
                          : item?.currency_code +
                            " " +
                            item?.amountAV +
                            "-" +
                            `(${item?.docDate.slice(0, 10)})` +
                            " " +
                            item?.supplier_name_Sender}
                        {/* {ishbrews === "he" ? "." + (index + 1) : ""} */}
                      </li>
                    );
                  })}
                  <Pagination
                    onPageChange={handlePageChange}
                    currentPage={selectedPage}
                    totalRecod={totalDocs}
                    pagePerRecord={pagePerRecord}
                    length1={inboxListByUser.length}
                  ></Pagination>
                </ul>
              </>
            ) :!isBusinessPartner && (
              <div className="flex justify-center items-start md:items-center h-full">
                <div className="content-box flex flex-col gap-2 shadow-md rounded-md">
                  <div>
                    <GoInbox
                      size={"36px"}
                      className="text-primary-color mx-auto font-bold"
                    />
                  </div>
                  <h2 className="text-base text-text-color text-center font-medium">
                    {t("tabinfos.Addbusiness")}
                  </h2>
                  <button
                    className="text-white font-bold mb-2 text-base bg-cyan-500 w-1/2 mx-auto my-2 rounded-md shadow-md py-2"
                    onClick={handleSubmitInvite}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        {ishbrews == "he" ? (
                          <>
                            <span className="">
                              ...{t("signin.Loading")}
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className=" mr-1"
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className=" mr-1"
                              />
                              {t("signin.Loading")}...
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>{t("tabinfos.Invitethem")}</>
                    )}
                  </button>
                  <Link
                    to="/main-console/business-partners"
                    className="flex justify-center"
                  >
                    <button
                      // onClick={handleWhatsAppButtonClick}
                      className="text-white font-bold mb-2 text-base bg-cyan-500 rounded-md shadow-md py-2 px-2"
                    >
                      {t("tabinfos.invite")}
                    </button>
                  </Link>
                  <p className="text-center">
                    <q className="italic text-[#c1c1c1] text-center text-sm ">
                      {t("tabinfos.Uninet")}
                    </q>
                  </p>
                </div>
              </div>
            )}
            {/* {inboxListByUser?.length > 0 ? (
              inboxListByUser?.map((item, index) => {
                const isActive = index === activeIndexes?.Inbox;
                return (
                  <ul className="links-list">
                    <li
                      className={` custom-paragraph flex items-center gap-2 cursor-pointer tab-text text-[13px] font-medium leading-9  text-black hover:underline hover:text-primary-color
                       ${isActive ? "active" : ""}
                        ${ishbrews === "he" ? "flex-row-reverse" : ""}`}
                      onClick={() => handleSubmit(item, index, "Inbox")}
                    >
                      <BiLink />
                      {ishbrews === "en" ? index + 1 + ". " : ""}
                      {ishbrews === "en"
                        ? item?.supplier_name_Sender +
                          " " +
                          `(${item?.docDate.slice(0, 10)})` +
                          "-" +
                          item?.amountAV +
                          " " +
                          item?.currency_code
                        : item?.currency_code +
                          " " +
                          item?.amountAV +
                          "-" +
                          `(${item?.docDate.slice(0, 10)})` +
                          " " +
                          item?.supplier_name_Sender}
                      {ishbrews === "he" ? "." + (index + 1) : ""}
                    </li>
                  </ul>
                );
              })
            ) : (
              <div className="flex justify-center items-start md:items-center h-full">
                <div className="content-box flex flex-col gap-2 shadow-md rounded-md">
                  <div>
                    <GoInbox
                      size={"36px"}
                      className="text-primary-color mx-auto font-bold"
                    />
                  </div>
                  <h2 className="text-base text-text-color text-center font-medium">
                    {t("tabinfos.Addbusiness")}
                  </h2>
                  <button
                    className="text-white font-bold mb-2 text-base bg-cyan-500 w-1/2 mx-auto my-2 rounded-md shadow-md py-2"
                    onClick={handleSubmitInvite}
                    disabled={loading}
                  >
                    {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className=" mr-1"
                    />
                    <span className="">{t("signin.Loading")}...</span>
                  </>
                ) : (
                  <>{t("tabinfos.Invitethem")}</>
                )}
                    
                  </button>
                  <button
                    onClick={handleWhatsAppButtonClick}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 rounded-md shadow-md py-2 px-2"
                  >
                    {t("tabinfos.invite")}
                  </button>
                  <p className="text-center">
                    <q className="italic text-[#c1c1c1] text-center text-sm ">
                      {t("tabinfos.Uninet")}
                    </q>
                  </p>
                </div>
              </div>
            )} */}
          </div>
        </Tab>
        <Tab
          eventKey="Entered"
          title={t("main-console.part66")}
          className="custom-tab"
        >
          <div className="custom-scrollbar api-scroll">
            {enteredLoading ? (
              <div className="border text-center text-black h-full flex justify-center items-center">
                <>
                  {ishbrews == "he" ? (
                    <>
                      <span className="">
                        ...{t("signin.Loading")}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="">
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                        {t("signin.Loading")}...
                      </span>
                    </>
                  )}
                </>
              </div>
            ) : approveListByUser?.length > 0 ? (
              <>
                <ul className="links-list">
                  {" "}
                  {approveListByUser?.map((item, index) => {
                    const isActive = index === activeIndexes?.Entered;
                    return (
                      <li
                        className={` custom-paragraph flex items-center gap-2 cursor-pointer tab-text text-[13px] font-medium leading-9  text-black hover:underline hover:text-primary-color
                        ${isActive ? "active" : ""}
                         ${ishbrews === "he" ? "flex-row-reverse" : ""}`}
                        onClick={() => handleSubmit(item, index, "Entered")}
                      >
                        <BiLink />
                        {/* {ishbrews === "en" ? index + 1 + ". " : ""} */}
                        {ishbrews === "en"
                          ? item?.supplier_name_Sender +
                            " " +
                            `(${item?.docDate.slice(0, 10)})` +
                            "-" +
                            item?.amountAV +
                            " " +
                            item?.currency_code
                          : item?.currency_code +
                            " " +
                            item?.amountAV +
                            "-" +
                            `(${item?.docDate.slice(0, 10)})` +
                            " " +
                            item?.supplier_name_Sender}
                        {/* {ishbrews === "he" ? "." + (index + 1) : ""} */}
                      </li>
                    );
                  })}
                  <Pagination
                    onPageChange={handlePageChange}
                    currentPage={selectedPage}
                    totalRecod={totalRecord}
                    pagePerRecord={pagePerRecord}
                    length1={approveListByUser.length}
                  ></Pagination>
                </ul>
              </>
            ) : !isBusinessPartner &&(
              <div className="flex justify-center items-start md:items-center h-full">
                <div className="content-box flex flex-col gap-2 shadow-md rounded-md">
                  <div>
                    <AiTwotoneEnvironment
                      size={"36px"}
                      className="text-primary-color mx-auto font-bold"
                    />
                  </div>
                  <h2 className="text-base text-text-color text-center font-medium">
                    {t("tabinfos.Addbusiness")}
                  </h2>
                  <button
                    onClick={handleSubmitInvite}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 w-1/2 mx-auto my-2 rounded-md shadow-md py-2"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        {ishbrews == "he" ? (
                          <>
                            <span className="">
                              ...{t("signin.Loading")}
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className=" mr-1"
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className=" mr-1"
                              />
                              {t("signin.Loading")}...
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>{t("tabinfos.Invitethem")}</>
                    )}
                    {/* {t("tabinfos.Invitethem")} */}
                  </button>
                  <button
                    onClick={handleWhatsAppButtonClick}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 rounded-md shadow-md py-2 px-2"
                  >
                    {t("tabinfos.invite")}
                  </button>
                  <p className="text-center">
                    <q className="italic text-[#c1c1c1] text-center text-sm ">
                      {t("tabinfos.Uninet")}
                    </q>
                  </p>
                </div>
              </div>
            )}
            {/* {approveListByUser?.length > 0 ? (
              approveListByUser?.map((item, index) => {
                const isActive = index === activeIndexes?.Entered;

                return (
                  <ul className="links-list">
                    <li
                      className={` custom-paragraph flex items-center gap-2 cursor-pointer tab-text text-[13px] font-medium leading-9  text-black hover:underline hover:text-primary-color
                      ${isActive ? "active" : ""}
                       ${ishbrews === "he" ? "flex-row-reverse" : ""}`}
                      onClick={() => handleSubmit(item, index, "Entered")}
                    >
                      <BiLink />
                      {ishbrews === "en" ? index + 1 + ". " : ""}
                      {ishbrews === "en"
                        ? item?.supplier_name_Sender +
                          " " +
                          `(${item?.docDate.slice(0, 10)})` +
                          "-" +
                          item?.amountAV +
                          " " +
                          item?.currency_code
                        : item?.currency_code +
                          " " +
                          item?.amountAV +
                          "-" +
                          `(${item?.docDate.slice(0, 10)})` +
                          " " +
                          item?.supplier_name_Sender}
                      {ishbrews === "he" ? "." + (index + 1) : ""}
                    </li>
                  </ul>
                );
              })
            ) : (
              <div className="flex justify-center items-start md:items-center h-full">
                <div className="content-box flex flex-col gap-2 shadow-md rounded-md">
                  <div>
                    <AiTwotoneEnvironment
                      size={"36px"}
                      className="text-primary-color mx-auto font-bold"
                    />
                  </div>
                  <h2 className="text-base text-text-color text-center font-medium">
                    {t("tabinfos.Addbusiness")}
                  </h2>
                  <button
                    onClick={handleSubmitInvite}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 w-1/2 mx-auto my-2 rounded-md shadow-md py-2"
                  >
                    {t("tabinfos.Invitethem")}
                  </button>
                  <button
                    onClick={handleWhatsAppButtonClick}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 rounded-md shadow-md py-2 px-2"
                  >
                    {t("tabinfos.invite")}
                  </button>
                  <p className="text-center">
                    <q className="italic text-[#c1c1c1] text-center text-sm ">
                      {t("tabinfos.Uninet")}
                    </q>
                  </p>
                </div>
              </div>
            )} */}
          </div>
        </Tab>
        <Tab
          eventKey="Rejected"
          title={t("main-console.part67")}
          className="custom-tab"
        >
          <div className="custom-scrollbar api-scroll">
            {rejectLoader === true ? (
              <div className="border text-center text-black h-full flex justify-center items-center">
                <>
                  {ishbrews == "he" ? (
                    <>
                      <span className="">
                        ...{t("signin.Loading")}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="">
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className=" mr-1"
                        />
                        {t("signin.Loading")}...
                      </span>
                    </>
                  )}
                </>
              </div>
            ) : rejectedListByUser?.length > 0 ? (
              <>
                <ul className="links-list">
                  {rejectedListByUser?.map((item, index) => {
                    const isActive = index === activeIndexes?.Rejected;
                    return (
                      <li
                        className={` custom-paragraph flex items-center gap-2 cursor-pointer tab-text text-[13px] font-medium leading-9  text-black hover:underline hover:text-primary-color
                       ${isActive ? "active" : ""}
                        ${ishbrews === "he" ? "flex-row-reverse" : ""}`}
                        onClick={() => handleSubmit(item, index, "Rejected")}
                      >
                        <BiLink />
                        {/* {ishbrews === "en" ? index + 1 + ". " : ""} */}
                        {ishbrews === "en"
                          ? item?.supplier_name_Sender +
                            " " +
                            `(${item?.docDate.slice(0, 10)})` +
                            "-" +
                            item?.amountAV +
                            " " +
                            item?.currency_code
                          : item?.currency_code +
                            " " +
                            item?.amountAV +
                            "-" +
                            `(${item?.docDate.slice(0, 10)})` +
                            " " +
                            item?.supplier_name_Sender}
                        {/* {ishbrews === "he" ? "." + (index + 1) : ""} */}
                      </li>
                    );
                  })}
                  <Pagination
                    onPageChange={handlePageChange}
                    currentPage={selectedPage}
                    totalRecod={totalRecord}
                    pagePerRecord={pagePerRecord}
                    length1={rejectedListByUser.length}
                  ></Pagination>
                </ul>
              </>
            ) :!isBusinessPartner && (
              <div className="flex justify-center items-center h-full">
                <div className="content-box flex flex-col gap-2 shadow-md rounded-md">
                  <div>
                    <BsFillBookmarkXFill
                      size={"36px"}
                      className="text-primary-color mx-auto font-bold"
                    />
                  </div>
                  <h2 className="text-base text-text-color text-center font-medium">
                    {t("tabinfos.Addbusiness")}
                  </h2>
                  <button
                    onClick={handleSubmitInvite}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 w-1/2 mx-auto my-2 rounded-md shadow-md py-2"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        {ishbrews == "he" ? (
                          <>
                            <span className="">
                              ...{t("signin.Loading")}
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className=" mr-1"
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="">
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className=" mr-1"
                              />
                              {t("signin.Loading")}...
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>{t("tabinfos.Invitethem")}</>
                    )}
                  </button>
                  <button
                    onClick={handleWhatsAppButtonClick}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 rounded-md shadow-md py-2 px-2"
                  >
                    {t("tabinfos.invite")}
                  </button>
                  <p className="text-center">
                    <q className="italic text-[#c1c1c1] text-center text-sm ">
                      {t("tabinfos.Uninet")}
                    </q>
                  </p>
                </div>
              </div>
            )}
            {/* {rejectedListByUser?.length > 0 ? (
              rejectedListByUser?.map((item, index) => {
                const isActive = index === activeIndexes?.Rejected;
                return (
                  <ul className="links-list">
                    <li
                      className={` custom-paragraph flex items-center gap-2 cursor-pointer tab-text text-[13px] font-medium leading-9  text-black hover:underline hover:text-primary-color
                     ${isActive ? "active" : ""}
                      ${ishbrews === "he" ? "flex-row-reverse" : ""}`}
                      onClick={() => handleSubmit(item, index, "Rejected")}
                    >
                      <BiLink />
                      {ishbrews === "en" ? index + 1 + ". " : ""}
                      {ishbrews === "en"
                        ? item?.supplier_name_Sender +
                          " " +
                          `(${item?.docDate.slice(0, 10)})` +
                          "-" +
                          item?.amountAV +
                          " " +
                          item?.currency_code
                        : item?.currency_code +
                          " " +
                          item?.amountAV +
                          "-" +
                          `(${item?.docDate.slice(0, 10)})` +
                          " " +
                          item?.supplier_name_Sender}
                      {ishbrews === "he" ? "." + (index + 1) : ""}
                    </li>
                  </ul>
                );
              })
            ) : (
              <div className="flex justify-center items-center h-full">
                <div className="content-box flex flex-col gap-2 shadow-md rounded-md">
                  <div>
                    <BsFillBookmarkXFill
                      size={"36px"}
                      className="text-primary-color mx-auto font-bold"
                    />
                  </div>
                  <h2 className="text-base text-text-color text-center font-medium">
                    {t("tabinfos.Addbusiness")}
                  </h2>
                  <button
                    onClick={handleSubmitInvite}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 w-1/2 mx-auto my-2 rounded-md shadow-md py-2"
                  >
                    {t("tabinfos.Invitethem")}
                  </button>
                  <button
                    onClick={handleWhatsAppButtonClick}
                    className="text-white font-bold mb-2 text-base bg-cyan-500 rounded-md shadow-md py-2 px-2"
                  >
                    {t("tabinfos.invite")}
                  </button>
                  <p className="text-center">
                    <q className="italic text-[#c1c1c1] text-center text-sm ">
                      {t("tabinfos.Uninet")}
                    </q>
                  </p>
                </div>
              </div>
            )} */}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default TabsInfo;
