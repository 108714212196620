import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../services/api";
import { toast } from "react-toastify";
import { TokenExpired } from "../services/tokenExpired";

const CreditForm = ({
  setLoading,
  setBillingResponse,
  setBillingStatus,
  setShowFirstTime,
  showFirstTime,
  showExceedsMessage,
  refresh,
  subId,
}) => {
  const [cardNumber, setCardNumber] = useState();
  const [expiryMonth, setExpiryMonth] = useState();
  const [expiryYear, setExpiryYear] = useState();
  const [cvv, setCvv] = useState();
  const [cardHolderName, setCardHolderName] = useState();
  const [errors, setErrors] = useState({});
  const [formTouch, setFromTouch] = useState(false);
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const { t, i18n } = useTranslation();
  const refreshToken = localStorage.getItem("refreshToken");
  let ishbrews = localStorage.getItem("i18nextLng");
  const { setTokenExpired } = useContext(AuthUserContext);
  useEffect(() => {
    if (formTouch) {
      const fields = {
        cardNumber,
        expiryMonth,
        expiryYear,
        cvv,
        cardHolderName,
      };
      let validationErrors = {};
      Object.keys(fields).forEach((key) => {
        let value = fields[key];
        let error = validateField(key, value);
        if (error) {
          validationErrors[key] = error;
        }
      });
      setErrors(validationErrors);
    }
  }, [i18n.language]);

  useEffect(() => {
    clearValue();
  }, [refresh]);

  const validateField = (name, value) => {
    let error = "";
    if (!value) {
      error = `${t(`credit-debit.${name}`)} ${t("credit-debit.required")}`;
    } else {
      switch (name) {
        case "cardNumber":
          if (!/^\d{16}$/.test(value)) error = t("credit-debit.invalidCard");
          break;
        case "expiryMonth":
          if (!/^(0[1-9]|1[0-2])$/.test(value))
            error = t("credit-debit.invalidMonth");
          break;
        case "expiryYear":
          if (!/^\d{4}$/.test(value)) error = t("credit-debit.invalidYear");
          break;
        case "cvv":
          if (!/^\d{3}$/.test(value)) error = t("credit-debit.invalidCVC");
          break;
        case "cardHolderName":
          if (!value) error = t("credit-debit.required");
          break;
        default:
          break;
      }
    }
    return error;
  };

  const handleChange = (e) => {
    setFromTouch(true);
    const { name, value } = e.target;
    switch (name) {
      case "cardNumber":
        setCardNumber(value);
        break;
      case "expiryMonth":
        setExpiryMonth(value);
        break;
      case "expiryYear":
        setExpiryYear(value);
        break;
      case "cvv":
        setCvv(value);
        break;
      case "cardHolderName":
        setCardHolderName(value);
        break;
      default:
        break;
    }
    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const clearValue = () => {
    setCardHolderName("");
    setCvv("");
    setCardNumber("");
    setExpiryMonth("");
    setExpiryYear("");
    setErrors("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fields = { cardNumber, expiryMonth, expiryYear, cvv, cardHolderName };
    let validationErrors = {};
    Object.keys(fields).forEach((key) => {
      let value = fields[key];
      let error = validateField(key, value);
      if (error) {
        validationErrors[key] = error;
      }
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      fields.Lang = ishbrews == "he" ? 2 : 1;
      if (token) {
        setLoading(true);
        Api.SaveBillingDetails(fields, token)
          .then(async (res) => {
            setLoading(false);
            setBillingResponse(res);
            setBillingStatus(true);
            clearValue();
          })
          .catch(async (err) => {
            if (err?.status === 401) {
              if (refreshToken) {
                await Api.refreshToken({
                  authenticationToken: refreshToken,
                })
                  .then(async (res) => {
                    localStorage.setItem("accessToken", res?.accessToken);
                    localStorage.setItem("refreshToken", res?.refreshToken);
                    const expiredSecond = await TokenExpired.getExpiredMinutes(
                      res?.RefreshTokenExpiredTime
                    );
                    setTokenExpired(expiredSecond);
                    await Api.SaveBillingDetails(fields, res?.accessToken)
                      .then(async (res) => {
                        setLoading(false);
                        setBillingResponse(res);
                        setBillingStatus(true);
                      })
                      .catch(async (err) => {
                        setLoading(false);
                        toast.error(err?.message ?? err);
                      });
                  })
                  .catch((err) => {
                    if (err?.status === 400) {
                      toast.error(t("token.authentication-message"));
                      history.push("/");
                    }
                  });
              } else {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            } else {
              setLoading(false);
              toast.error(e?.message ?? e);
            }
            setLoading(false);
            if (err?.status === 400) {
              toast.error(t("token.authentication-message"));
              history.push("/");
            } else {
              toast.error(t("token.authentication-message"));
              history.push("/");
            }
          });
      } else {
        setLoading(false);
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    }
  };
  const ContinueFree = () => {
    if (token) {
      const data = {
        mainCompanyId: localStorage.getItem("businessId")
          ? JSON.parse(localStorage.getItem("businessId"))
          : JSON.parse(localStorage.getItem("mainCompanyId")),
        subCopmanyId: subId,
      };
      setLoading(true);
      Api.SaveFristTimeBillingMessage(data, token)
        .then(async (res) => {
          setLoading(false);
          setShowFirstTime(false);
        })
        .catch(async (err) => {
          if (err?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  const expiredSecond = await TokenExpired.getExpiredMinutes(
                    res?.RefreshTokenExpiredTime
                  );
                  setTokenExpired(expiredSecond);
                  await Api.SaveFristTimeBillingMessage(data, res?.accessToken)
                    .then(async (res) => {
                      setLoading(false);
                      setShowFirstTime(false);
                    })
                    .catch(async (err) => {
                      setLoading(false);
                      toast.error(err?.message ?? err);
                    });
                })
                .catch((err) => {
                  if (err?.status === 400) {
                    toast.error(t("token.authentication-message"));
                    history.push("/");
                  }
                });
            } else {
              toast.error(t("token.authentication-message"));
              history.push("/");
            }
          } else {
            setLoading(false);
            toast.error(err?.message ?? err);
          }
          setLoading(false);
          if (err?.status === 400) {
            toast.error(t("token.authentication-message"));
            history.push("/");
          } else {
            toast.error(t("token.authentication-message"));
            history.push("/");
          }
        });
    } else {
      setLoading(false);
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  };

  return (
    <div className="mt-1">
      <form
        className={`max-w-md mx-auto  ${
          ishbrews === "he" ? "text-right" : "text-left"
        }`}
        style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
        onSubmit={handleSubmit}
      >
        <h2
          className={`text-2xl font-semibold text-black-700 text-center mb-2`}
        >
          {t("credit-debit.title")}
        </h2>

        <div className="mb-2">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="cardNumber"
          >
            {t("credit-debit.cardNumber")}
          </label>
          <input
            type="text"
            id="cardNumber"
            name="cardNumber"
            className={
              ishbrews === "he"
                ? `block w-full px-2 py-[14px]  md:py-[10px] text-right text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                    errors.cardNumber ? "outline outline-red-200" : ""
                  }`
                : `block w-full px-2 py-[14px]  md:py-[10px]  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid  rounded-lg appearance-none border-bg-border bg-clip-padding ${
                    errors.cardNumber ? "outline outline-red-200" : ""
                  }`
            }
            value={cardNumber}
            onBlur={handleChange}
            onChange={handleChange}
          />
          {errors.cardNumber && (
            <p className="text-red-500 text-sm ">{errors.cardNumber}</p>
          )}
        </div>

        <div
          className={
            ishbrews === "he"
              ? "flex flex-row-reverse  gap-4"
              : "flex flex-row   gap-4"
          }
        >
          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="expiryMonth"
            >
              {t("credit-debit.expiryMonth")}
            </label>
            <input
              type="text"
              id="expiryMonth"
              name="expiryMonth"
              className={
                ishbrews === "he"
                  ? `block w-full px-2 py-[14px]  md:py-[10px] text-right text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                      errors.expiryMonth ? "outline outline-red-200" : ""
                    }`
                  : `block w-full px-2 py-[14px]  md:py-[10px]  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                      errors.expiryMonth ? "outline outline-red-200" : ""
                    }`
              }
              value={expiryMonth}
              onBlur={handleChange}
              onChange={handleChange}
            />
            {errors.expiryMonth && (
              <p className="text-red-500 text-sm ">{errors.expiryMonth}</p>
            )}
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="expiryYear"
            >
              {t("credit-debit.expiryYear")}
            </label>
            <input
              type="text"
              id="expiryYear"
              name="expiryYear"
              className={
                ishbrews === "he"
                  ? `block w-full px-2 py-[14px]  md:py-[10px] text-right  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                      errors.expiryYear ? "outline outline-red-200" : ""
                    }`
                  : `block w-full px-2 py-[14px]  md:py-[10px]  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                      errors.expiryYear ? "outline outline-red-200" : ""
                    }`
              }
              value={expiryYear}
              onBlur={handleChange}
              onChange={handleChange}
            />
            {errors.expiryYear && (
              <p className="text-red-500 text-sm ">{errors.expiryYear}</p>
            )}
          </div>
        </div>

        <div className="mb-2">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="cvv"
          >
            {t("credit-debit.cvv")}
          </label>
          <input
            type="text"
            id="cvv"
            name="cvv"
            className={
              ishbrews === "he"
                ? `block w-full px-2 py-[14px]  md:py-[10px] text-right  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                    errors.cvc ? "outline outline-red-200" : ""
                  }`
                : `block w-full px-2 py-[14px]  md:py-[10px]  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                    errors.cvv ? "outline outline-red-200" : ""
                  }`
            }
            value={cvv}
            onBlur={handleChange}
            onChange={handleChange}
          />
          {errors.cvv && (
            <p className="text-red-500 text-sm mt-1">{errors.cvv}</p>
          )}
        </div>

        <div className="mb-2">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="cardHolderName"
          >
            {t("credit-debit.cardHolderName")}
          </label>
          <input
            type="text"
            id="cardHolderName"
            name="cardHolderName"
            className={
              ishbrews === "he"
                ? `block w-full px-2 py-[14px]  md:py-[10px] text-right  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                    errors.cardHolderName ? "outline outline-red-200" : ""
                  }`
                : `block w-full px-2 py-[14px]  md:py-[10px]  text-base md:text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding ${
                    errors.cardHolderName ? "outline outline-red-200" : ""
                  }`
            }
            value={cardHolderName}
            onBlur={handleChange}
            onChange={handleChange}
          />
          {errors.cardHolderName && (
            <p className="text-red-500 text-sm">{errors.cardHolderName}</p>
          )}
        </div>
        <div
          className={`mb-2`}
          style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
        >
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="cardHolderName"
          >
            {t("credit-debit.condition1")}{" "}
            <a
              href="https://uninet-io.com/en/term-of-use-en/"
              className={"text-blue-600"}
            >
              {" "}
              {t("credit-debit.term")}
            </a>{" "}
            {t("credit-debit.end")}{" "}
            <a
              href="https://uninet-io.com/en/privacy-policy-en/"
              className={"text-blue-600"}
            >
              {t("credit-debit.privacy")}{" "}
            </a>
          </label>
        </div>
        <div className={`flex flex-column gap-2 mt-2`}>
          <button
            className=" bg-bg-secondary w-full flex justify-center  gap-2 items-center text-white py-2 px-4 rounded-lg "
            type="submit"
          >
            {t("credit-debit.save")}
          </button>
          {showExceedsMessage && (
            <button
              className="w-full flex justify-center bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
              onClick={() => {
                history.push("/");
                localStorage.clear();
                toast.success(t("Signup.outScuccess"));
              }}
            >
              {t("billing.maybeNext")}
            </button>
          )}
          {showFirstTime && (
            <button
              className="w-full flex justify-center bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
              onClick={ContinueFree}
            >
              {t("billing.continueFree")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreditForm;
