import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsWhatsapp } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import SelectField from "./selectField";
import Api from "../services/api";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { TokenExpired } from "../services/tokenExpired";

const BusinessDetails = ({ step, setStep, isLastStep,refresh,sidebar }) => {
  const history = useHistory();
  const location = useLocation();
  const { sidebarWidth = 0 } = location.state || {};
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState([]);
  const initialQuestionnaireValues = {
    externalSystemId: null,
  };
  let ishbrews = localStorage.getItem("i18nextLng");
  const [questionnaireValues, setQuestionnaireValues] = useState({
    ...initialQuestionnaireValues,
  });
    const [selectedData, setSelectedData] = useState();
  const { businessId, setTokenExpired} = useContext(AuthUserContext);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 992);

  const [showDialog, setShowDialog] = useState(false);
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [externalSystem, setExternalSystem] = useState({});
  const [inputFieldDetails, setInputFieldDetails] = useState([]);
  const [inputFieldList, setInputFieldList] = useState([]);
  const [error, setError] = useState(false);
  const [logoIcons, setLogoIcons] = useState(null);
  const [videoLinks, setvideoLinks] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [textResponse, setTextResponse] = useState('')
  // console.log("questionnaireValues", questionnaireValues?.externalSystemId);
  const handleWhatsAppButtonClick = () => {
    const whatsappUrl = "https://api.whatsapp.com/send?phone=972584222456";
    window.open(whatsappUrl, "_blank");
  };
  const onExternalSystemChange = (value) => {
    setInputFieldDetails();
    setInputFieldList();
    setSelectedData(value);
    setQuestionnaireValues((prev) => ({
      ...prev,
      externalSystemId: value?.value,
    }));
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const initializeInputField = (length) => {
    return Array.from({ length }, () => false);
    };
    if (token) {
      Api.getExternalCustomizedField(value?.value, token)
        .then(async (res) => {
          setInputFieldList(res?.listInputLabelDetails);
          setShowPassword(initializeInputField(res?.listInputLabelDetails.length));
          
          setLogoIcons(res?.logoIcon);
          setvideoLinks(res?.videoLink);
          setInputFieldDetails(res?.listInputLabelDetails);
        })
        .catch(async (e) => {
          if (e?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  // Assuming this is inside a React component function
                    const expiredSecond = await TokenExpired.getExpiredMinutes(res?.RefreshTokenExpiredTime);
                  if (sidebarWidth) {
                    setTokenExpired(expiredSecond);
                    }

                  Api.getExternalCustomizedField(value?.value, res?.accessToken)
                    .then(async (res) => {
                      setInputFieldList(res?.listInputLabelDetails);
                       
                      setLogoIcons(res?.logoIcon);
                      setvideoLinks(res?.videoLink);
                      setInputFieldDetails(res?.listInputLabelDetails);
                    })
                    .catch(async (e) => {
                      toast.error(e.message ?? e);
                    });
                })
                .catch((err) => {
                  if (err?.status === 400) {
                    history.push("/");
                    toast.error(t("token.authentication-message"));
                  }
                });
            } else {
              history.push("/");
              toast.error(t("token.authentication-message"));
            }
          } else {
            // history.push("/");
            toast.error(e?.message ?? e);
          }
        });
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  };
  const handleInputChange = (fieldName, newValue) => {
    try {
      setInputFieldDetails((prevDetails) => {
        const updatedDetails = prevDetails.map((field) => {
          if (field?.FieldLabelName === fieldName) {
            if (fieldName === "user_email") {
              if (!/\S+@\S+\.\S+/.test(newValue)) {
                setEmailError(true);
              } else {
                setEmailError(false);
              }
            }
            return {
              ...field,
              FieldLabelValue: newValue,
            };
          }
          return field;
        });
        return updatedDetails;
      });
    } catch (e) {
      console.log("e", e);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateInputFields = () => {
    const errors = {};
    inputFieldDetails.forEach((element) => {
      if (!element.FieldLabelValue) {
        errors[element.FieldLabelName] = t("Questionnaire3.inputFieldRequired");
      } else if (
        element.FieldLabelName === "user_email" &&
        questionnaireValues?.externalSystemId === 2 &&
        !isValidEmail(element.FieldLabelValue)
      ) {
        errors[element.FieldLabelName] = "Invalid email address";
      }
    });
    setFieldErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  const openWhatsApp = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=972584222456`;
    window.open(whatsappURL, "_blank");
    closeDialog();
  };

  const closeDialog = () => {
    setShowDialog(false);
    setShowResponseDialog(false)
  };

  const showPrompt = () => {
    setShowDialog(true);
  };
  const showResponsePrompt = () => {
  setShowResponseDialog(true)
  }
  const handleSUbmit = async () => {
    if (inputFieldList.length === 0) {
      setError(true);
      return false;
    }
    setError(false);
    const isValid = validateInputFields();
    if (isValid) {
      setLoading(true);
      const token = localStorage?.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      try {
        if (token) {
          let data = { listInputLabelDetails: [] };
          inputFieldDetails.forEach((element) => {
            data.listInputLabelDetails.push({
              FieldLabelName: element?.FieldLabelName,
              FieldLabelValue: element?.FieldLabelValue,
            });
          });
          data.ExternalSystemId = selectedData.value ? selectedData.value : questionnaireValues?.externalSystemId;
          data.Companyid = localStorage.getItem("businessId") ? localStorage.getItem("businessId") : localStorage.getItem("mainCompanyId");
          data.Lang = ishbrews === "he" ? 2 : 1;
          await Api.SaveExternalCustomizedExternalSystemId(data, token)
            .then((response) => {
              localStorage.removeItem("accountStep");
              if(response?.success === false && 
                response?.systemRegisteredInuninet === false && 
                response?.validExternalsystemCredenatials === false){
                  setLoading(false);
                  setTextResponse(response?.textResponse)
                  showResponsePrompt()
              } else if(
                response?.success === false && 
                response?.systemRegisteredInuninet === false && 
                response?.validExternalsystemCredenatials === true
              ){
                setLoading(false);
                  setTextResponse(response?.textResponse)
                  showResponsePrompt()
              } else if(response?.success === false && 
                response?.systemRegisteredInuninet === false && 
                response?.validExternalsystemCredenatials === null){
                  setLoading(false);
                  showPrompt();
                } else {
                  toast.success(response?.textResponse);
                  localStorage.setItem("UserName", response?.fullName);
                  setTimeout(() => {
                    history.push("/welcomeScreen");
                  }, 2000);
                }
            })
            .catch(async (e) => {
              console.log("e", e);

              if (e?.status === 401) {
                if (refreshToken) {
                  await Api.refreshToken({
                    authenticationToken: refreshToken,
                  })
                    .then(async (res) => {
                      localStorage.setItem("accessToken", res?.accessToken);
                      localStorage.setItem("refreshToken", res?.refreshToken);
                      const expiredSecond = await TokenExpired.getExpiredMinutes(res?.RefreshTokenExpiredTime);
                      if (sidebarWidth) {
                        setTokenExpired(expiredSecond);
                        }
                      await Api.SaveExternalCustomizedExternalSystemId(
                        data,
                        res?.accessToken
                      )
                        .then((response) => {
                          localStorage.removeItem("accountStep");
                          localStorage.removeItem("accountStep");
              if(response?.success === false && 
                response?.systemRegisteredInuninet === false && 
                response?.validExternalsystemCredenatials === false){
                  setLoading(false);
                  setTextResponse(response?.textResponse)
                  showResponsePrompt()
              } else if(
                response?.success === false && 
                response?.systemRegisteredInuninet === false && 
                response?.validExternalsystemCredenatials === true
              ){
                setLoading(false);
                  setTextResponse(response?.textResponse)
                  showResponsePrompt()
              } else if(response?.success === false && 
                response?.systemRegisteredInuninet === false && 
                response?.validExternalsystemCredenatials === null){
                  setLoading(false);
                  showPrompt();
                } else {
                  toast.success(response?.textResponse);
                  localStorage.setItem("UserName", response?.fullName);
                  setTimeout(() => {
                    history.push("/welcomeScreen");
                  }, 2000);
                }
                        })
                        .catch(async (e) => { 
                          setLoading(false);
                          toast.error(e?.message ?? e);
                        });
                    })
                    .catch((err) => {
                      if (err?.status === 400) {
                        history.push("/");
                        toast.error(t("token.authentication-message"));
                      }
                    });
                } else {
                  toast.error(t("token.authentication-message"));
                  history.push("/");
                }
              } else {
                setLoading(false);
                toast.error(e?.message ?? e);
              }
            });
        } else {
          toast.error(t("token.authentication-message"));
          history.push("/");
          setLoading(false);
        }
      } catch (e) {
        console.log("e", e);
        toast.error(t("token.authentication-message"));
        history.push("/");
      }
    } else {
      const firstErrorField = Object.keys(fieldErrors)[0];
      if (firstErrorField) {
        document.getElementById(firstErrorField)?.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    setInputFieldList();
    setLogoIcons();
    setvideoLinks();
    setInputFieldDetails();

    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const lang = ishbrews === "he" ? 2 : 1;
    if (token) {
      Api.getExternalSystem(lang, token)
        .then(async (res) => {
          const options = Object.keys(res).map((key) => ({
            label: res[key],
            value: parseInt(key, 10),
          }));
          setExternalSystem(options);
        })
        .catch(async (e) => {
          if (e?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  const expiredSecond = await TokenExpired.getExpiredMinutes(res?.RefreshTokenExpiredTime);
                  if (sidebarWidth) {
                    setTokenExpired(expiredSecond);
                    }
                  Api.getExternalSystem(res?.accessToken)
                    .then(async (res) => {
                      const options = Object.keys(res).map((key) => ({
                        label: res[key],
                        value: parseInt(key, 10),
                      }));
                      setExternalSystem(options);
                    })
                    .catch((e) => {
                      toast.error(e?.message ?? e);
                    });
                })
                .catch((err) => {
                  if (err?.status === 400) {
                    toast.error(t("token.authentication-message"));
                    history.push("/");
                  }
                });
            } else {
              toast.error(t("token.authentication-message"));
              history.push("/");
            }
          } else {
            toast.error(e?.message ?? e);
          }
        });
    } else {
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  }, [refresh]);

  useEffect(() => {
    if (sidebarWidth) {
      const handleResize = () => {
        setIsScreenSmall(window.innerWidth < 992);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const containerMediaQueryStyle = {
    width: '100%',
    margin: '0',
  };

  const containerStyle = {
    width: `calc(100% - ${sidebar}px)`,
    marginRight: ishbrews === 'he' ? `${sidebar}px` : '',
    marginLeft: ishbrews === 'he' ? '' : `${sidebar}px`,
  };

  useEffect(() => {
    
  },[sidebarWidth])
  const style1 = sidebarWidth
    ? (isScreenSmall ? containerMediaQueryStyle : containerStyle)
    : {};
  return (
    <div
      style={style1}
      className={ "container-fluid p-0 "}
    >
      <div  className="content bg-bg-btn rounded-md py-3 md:py-4 px-3 md:px-4 m-3">
      <div>
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
      </div>
      {showResponseDialog && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-50 back-color"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[550px] p-4 mx-2 md:mx-0">
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <span className="cursor-pointer" onClick={() => closeDialog()}>
                <RxCross2
                  size={"30px"}
                  className="text-text-color cursor-pointer font-normal"
                />
              </span>
            </div>
            {/* <h1
              className={
                ishbrews === "he"
                  ? "text-2xl text-right text-text-color mb-2 font-medium"
                  : "text-2xl text-text-color mb-2 font-medium"
              }
            >
              Integration issue
            </h1> */}

            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-sm mb-4"
                  : "text-gray-800 text-sm mb-4"
              }
            >
              {textResponse}
            </p>
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <button
                className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                onClick={() => openWhatsApp()}
              >
                <span>
                  <BsWhatsapp size={"30px"} />
                </span>
                <span>Whatsapp</span>
              </button>
              <button
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                onClick={() => closeDialog()}
              >
                {t("Questionnaire3.modalOkText")}
              </button>
            </div>
          </div>
        </div>
      )}
      {showDialog && (
        <div
          id="custom-prompt"
          className="fixed flex items-center justify-center flex-col z-50 mx-2 md:mx-0 back-color"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-[550px] p-4 ">
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <span className="cursor-pointer" onClick={() => closeDialog()}>
                <RxCross2
                  size={"30px"}
                  className="text-text-color cursor-pointer font-normal"
                />
              </span>
            </div>
            <h1
              className={
                ishbrews === "he"
                  ? "text-2xl text-right text-text-color mb-2 font-medium"
                  : "text-2xl text-text-color mb-2 font-medium"
              }
            >
              {t("Questionnaire3.Integrationissue")}
            </h1>

            <p
              className={
                ishbrews === "he"
                  ? "text-gray-800 text-right text-sm mb-4"
                  : "text-gray-800 text-sm mb-4"
              }
            >
              {t("Questionnaire3.modalText")}
            </p>
            <div
              className={
                ishbrews === "he" ? "flex justify-start" : "flex justify-end"
              }
            >
              <button
                className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                onClick={() => openWhatsApp()}
              >
                <span>
                  <BsWhatsapp size={"30px"} />
                </span>
                <span>Whatsapp</span>
              </button>
              <button
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg"
                onClick={() => closeDialog()}
              >
                {t("Questionnaire3.modalOkText")}
              </button>
            </div>
          </div>
        </div>
        )}
        {sidebarWidth ? <h1 className="font-semibold text-3xl mb-[10px] text-center mt-3">
            {t("Questionnaire3.connectingNewUninet")}
          </h1> :
          <h1 className="font-semibold text-2xl mb-[10px] text-center mt-3">
            {t("Questionnaire3.part49")}
          </h1>
        }
      <p className="text-base font-normal text-gray-500 text-center">
        {t("Questionnaire1.pleasecheckout")} &nbsp;
        <span
          className={
            ishbrews === "he"
              ? "cursor-pointer text-primary-color text-left flex flex-row-reverse justify-center"
              : "cursor-pointer text-primary-color text-left"
          }
          onClick={handleWhatsAppButtonClick}
        >
          {t("Questionnaire1.part42")}
        </span>
      </p>

      <div className={ishbrews === "he" ? "row flex-row-reverse" : "row"}>
        <div
          className={ishbrews === "he" ? "col-md-12 text-right" : "col-md-12"}
        >
          <label
            htmlFor="firstNameInput"
            className="mb-[10px] text-sm font-semibold text-text-color mt-3"
          >
            {t("Questionnaire3.part50")}
            <span className="text-primary-color"> * </span>
          </label>{" "}
          <br />
          <SelectField
            options={externalSystem}
            defaultValue={selectedData}
            handleChange={onExternalSystemChange}
            placeholder={t("Questionnaire3.selectField-placeholder")}
          />
          {error && !questionnaireValues?.externalSystemId && (
            <span
              className={
                ishbrews === "he" ? "text-red-600 text-right" : "text-red-600"
              }
            >
              {t("Questionnaire1.selectAllFields")}
            </span>
          )}
        </div>
      </div>

      <div
        className={
          ishbrews === "he" ? "row flex-row-reverse  mt-2" : "row mt-2"
        }
      >
        <div
          className={ishbrews === "he" ? "col-md-12 text-right" : "col-md-12"}
        ></div>
      </div>

      <div className={ishbrews === "he" ? "row flex-row-reverse" : "row"}>
        <div
          className={ishbrews === "he" ? "col-md-12 text-right" : "col-md-12"}
        >
          {logoIcons && (
            <div className="mt-4 ">
              {ishbrews === "he" ? (
                <div className="mt-2 flex flex-row-reverse ">
                  <img src={logoIcons} alt="logoIcon" className="mx-auto" />
                </div>
              ) : (
                <div className="mt-2 mx-auto">
                  <img src={logoIcons} alt="logoIcon" className="mx-auto" />
                </div>
              )}
            </div>
          )}
            {inputFieldList &&
            inputFieldList?.map((item, idx) => {
              return (
                <div>
                  <div key={idx}>
                    <label
                      htmlFor="firstNameInput"
                      className="mb-[10px] text-sm font-semibold text-text-color "
                    >
                      {item?.FieldLabelName}
                      <span className="text-primary-color"> * </span>
                    </label>
                    <div
                      className={
                        ishbrews === "he"
                          ? "flex  flex-row-reverse items-center justify-between w-full px-2  py-[6px]  mb-[10px] text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding"
                          : "flex  items-center  justify-between w-full px-2  py-[6px]  mb-[10px] text-lg font-medium leading-normal text-gray-900 bg-white border border-solid rounded-lg appearance-none border-bg-border bg-clip-padding"
                      }
                    >
                      <input
                        style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}
                        className={`w-full`}
                      type={item?.FieldTypeDesc=="Password"?showPassword[idx] ? "textBox" : "Password": item?.FieldTypeDesc}
                      // id="listLabel"
                      required
                      value={item?.FieldLabelValue}
                      onChange={(event) => {
                        handleInputChange(
                          item.FieldLabelName,
                          event.target.value
                        );
                      }}
                      // onChange={}
                      />
                      {item?.FieldTypeDesc=="Password"&&
                        <div onClick={() => setShowPassword(prev => {
                          const newArray = [...prev];
                          newArray[idx] = !newArray[idx];
                          return newArray
                        })}>
                          {showPassword[idx] ? (
                            <AiFillEye size={25} />
                          ) : (
                            <AiFillEyeInvisible size={25} />
                          )}
                        </div>
                      }
            
                      </div>
                    {fieldErrors[item.FieldLabelName] && (
                      <span className="text-red-600">
                        {t("Questionnaire3.inputFieldRequired")}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}

          {videoLinks && (
            <div className="mt-4  flex justify-center">
              <ReactPlayer
                url={videoLinks}
                width="88%"
                height="400px"
                controls={true}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={
          ishbrews === "he"
            ? " flex flex-row-reverse justify-end  mt-20"
            : " flex flex-row justify-end  mt-20"
        }
      >
        {/* {step === 0 || isLastStep ? (
          <></>
        ) : (
          <button
            onClick={() => setStep(step - 1)}
            className=" bg-bg-secondary flex  text-base px-[22px] font-semibold items-center py-[9px] text-white rounded-md "
            id="backButton"
          >
            {ishbrews === "he" ? (
              <div className="flex items-center gap-2">
                {" "}
                <span>{t("Questionnaire2.Back")}</span>
                <BsChevronRight />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <BsChevronLeft />
                <span>{t("Questionnaire2.Back")}</span>
              </div>
            )}
          </button>
        )} */}

        <button
          onClick={() => handleSUbmit()}
          className=" bg-bg-secondary   text-base px-[22px] font-semibold flex gap-1 items-center py-[9px] text-white rounded-md "
        >
          {loading ? (
            <>
                    {ishbrews == "he" ?
                      <>
                  <span className="">...{t("signin.Loading")}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className=" mr-1"
                      /></span>
                      </> : <>
                  <span className="">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className=" mr-1"
                    />
                    {t("signin.Loading")}...</span></>
                    }
                  </>
          ) : (
            <>
              {ishbrews === "he" ? (
                <div className="flex items-center gap-2">
                  <BsChevronLeft />
                  <span>{t("Questionnaire1.part40")}</span>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  {" "}
                  <span>{t("Questionnaire1.part40")}</span>
                  <BsChevronRight />
                </div>
              )}
            </>
          )}
        </button>
      </div>
      </div>
      </div>
  );
};
export default BusinessDetails;
