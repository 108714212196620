import React from "react";
import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/esm/Spinner";
import { useTranslation } from "react-i18next";
const Datatables = ({
  businessPartnersValue,
  loading,
  columns,
  handleSort,
  handleSelectedRowsChange,
  toggledClearRows
}) => {
  const { t } = useTranslation();
  let ishbrews = localStorage.getItem("i18nextLng");
  return (
    <div className="datatable-width-set">
      <DataTable
        columns={columns}
        data={businessPartnersValue}
        fixedHeaderScrollHeight="300px"
        highlightOnHover
        noContextMenu
        responsive={true}
        selectableRowsRadio="radio"
        selectableRows
        // pagination
        onSelectedRowsChange={handleSelectedRowsChange} 
        clearSelectedRows={toggledClearRows}
        subHeaderAlign="center"
        direction={ishbrews == "he" ? "rtl" : "ltr"}
        progressPending={loading}
        onSort={handleSort}
        progressComponent={
          loading ? (
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div
              className=" d-flex justify-content-center align-items-center border"
              style={{ height: "400px" }}
            >
              {t("datatable.noRecordsFound")}
            </div>
          )
        }
        noDataComponent={
          loading ? (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              {t("datatable.noRecordsFound")}
            </div>
          )
        }
      />
    </div>
  );
};

export default Datatables;
