import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function SelectField({
  options,
  defaultValue,
  handleChange,
  placeholder,
}) {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
   const [selectedOption, setSelectedOption] = useState(defaultValue);
let ishbrews = localStorage.getItem("i18nextLng");
  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  return (
    <div className="mt-1" style={{ direction: ishbrews === 'he' ? 'rtl' : 'ltr' }}>
      <Select
        className="select-basic-single"
        classNamePrefix="select"
        value={selectedOption}
        isDisabled={isDisabled}
        isLoading={isLoading}
        // isClearable={isClearable}
        isRtl={isRtl}
        // isSearchable={isSearchable}
        name="client"
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
}
