import { t } from "i18next";
import React, { useEffect, useState } from "react";

const Pagination = ({
  onPageChange,
  currentPage,
  totalRecod,
  pagePerRecord,
  length1,
}) => {
  const ishbrews = localStorage.getItem("i18nextLng");
  const [totalPage, setTotalPage] = useState();
  const [recordPerPage, setRecordPerPage] = useState(pagePerRecord);

  useEffect(() => {
    setTotalPage(Math.ceil(totalRecod / recordPerPage));
  }, [totalRecod, recordPerPage]);
  const handlePageChange = (selectedPage) => {
    onPageChange(selectedPage, recordPerPage);
  };

  const handleRecordsPerPageChange = (value) => {
    setRecordPerPage(value);
    onPageChange(1, value);
  };

  const generateRecordOptions = (totalRecords, length1) => {
    const options = [];
    const uniqueOptions = new Set();

    for (let i = 5; i <= totalRecords; i += 5) {
      uniqueOptions.add(i);
    }

    if (totalRecords % 5 !== 0) {
      uniqueOptions.add(totalRecords);
    }
    if (length1 < totalRecords) {
      uniqueOptions.add(length1);
    }
    options.push(...uniqueOptions);
    options.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    return options;
  };

  return (
    <div
      className={`flex justify-center mt-3 mb-3 gap-2 tab-text text-[12px] font-medium text-black`}
    >
      <div
        className="flex flex-wrap justify-center gap-3"
        style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
      >
        <div
          className="mt-1"
          style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
        >
          <span>{t("pagination.show")} </span>

          <select
            className={`border-1 border-black rounded-lg p-1 `}
            onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
            value={recordPerPage > length1 ? length1 : recordPerPage}
          >
            {generateRecordOptions(totalRecod, length1).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>

          <span>
            &nbsp; {t("pagination.results")} {totalRecod}
          </span>
        </div>
        <div className="mt-1">
          <span> {t("pagination.page")} </span>
          <select
            className={`border-1 border-black rounded-lg p-1 cursor-pointer `}
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
          >
            {Array.from({ length: totalPage }, (_, i) => i).map((num) => (
              <option key={num} value={num + 1}>
                {num + 1}
              </option>
            ))}
          </select>
          &nbsp;
          <span>
            {t("pagination.of")} {totalPage}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
