import React from "react";
const ShowPdf = ({ pdfFile, setIsPDFLoaded }) => {
  const pdfURL = pdfFile?.docInfoUrl;
  const handlePDFLoad = () => {
    if (pdfURL) {
      setIsPDFLoaded(false);
    }
  };
  return (
    <div className="w-full md:w-1/2 h-full">
      <div className="pdf-viewer relative bg-white rounded-md">
        {pdfURL ? (
          <iframe
            className="min-h-screen w-full pdf-display"
            src={pdfURL}
            title="PDF Viewer"
            width="100%"
            height="100%"
            onLoad={handlePDFLoad}
            allowFullScreen
          ></iframe>
        ) : (
          <div className="shadow-pdf shadow-md"></div>
        )}
      </div>
    </div>
  );
};

export default ShowPdf;
