import { config } from "../configs";

export const TokenExpired = {
    getExpiredMinutes: (expireDate) => {
        const date = new Date(expireDate);
        const expiredHours = date.getHours();
        const expiredMinutes = date.getMinutes();
        const expiredTotalMin = expiredHours * 60 + expiredMinutes;
        const israelTime = TokenExpired.getIsraelTime();
        const nowTimeMinites = parseInt(israelTime.hour) * 60 + parseInt(israelTime.minute);
        const min = config.expiredMin ? config.expiredMin : 2;
        const diff = (expiredTotalMin - nowTimeMinites) - min;
        localStorage.setItem("refershTokenExpired",diff);
        return diff;
    },

    getIsraelTime: () => {
        const options = {
            timeZone: 'Asia/Jerusalem',
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(new Date());

        let hour = parts.find(part => part.type === 'hour').value;
        let minute = parts.find(part => part.type === 'minute').value;

        
        hour = hour.padStart(2, '0'); 
        minute = minute.padStart(2, '0'); 
        return { hour, minute };
    }
}
