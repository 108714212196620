import React, { createContext, useEffect, useState } from "react";
export const AuthUserContext = createContext();
const AuthState = (props) => {
  const [encryptedUser, setEncryptedUser] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [taostValue, setTaostValue] = useState(localStorage.getItem("i18nextLng"));
  const [url, setUrl] = useState("");
  const [tokenExpired, setTokenExpired] = useState(null);
  const [isExpiredToken, setIsExpiredToken] = useState(false);

    useEffect(() => {
      let timeoutId;
      if (tokenExpired !== null) {
         const expiredMl = (tokenExpired * 60 * 1000) - 30000;
        timeoutId = setTimeout(() => {
        setIsExpiredToken(true);
      },expiredMl);
  
      }
      return () => {
      clearTimeout(timeoutId);
    };
  }, [tokenExpired,setTokenExpired,isExpiredToken]);
  return (
    <AuthUserContext.Provider
      value={{ encryptedUser, setEncryptedUser, setBusinessId, businessId, taostValue, setTaostValue, setUrl, url,tokenExpired,setTokenExpired,isExpiredToken,setIsExpiredToken}}
    >
      {props.children}
    </AuthUserContext.Provider>
  );
};
export default AuthState;
