import React, { useContext, useEffect, useState } from "react";
import Datatables from "../../components/datatable";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Api from "../../services/api";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/pagination";
import InviteModal from "../../components/inviteModal";
import Spinner from "react-bootstrap/Spinner";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TokenExpired } from "../../services/tokenExpired";
import { AuthUserContext } from "../../context";

const BusinessPartners = ({
  sidebarWidth,
  setBusinessPartner,
  subId,
  refresh,
  setTableLoading,
  tableLoading,
}) => {
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 992);
  let ishbrews = localStorage.getItem("i18nextLng");
  const [businessPartnersValue, setBusinessPartnersValue] = useState([]);
  const [sortedBusinessPartnersValue, setSortedBusinessPartnersValue] =
    useState([]);
  const [filteredBusinessPartnersValue, setFilteredBusinessPartnersValue] =
    useState([]);
    const { setTokenExpired } = useContext(AuthUserContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(3);
  const pageSize = 5;
  const [pagePerRecord, setPagePerRecord] = useState(5);
  const { t } = useTranslation();
  const history = useHistory();
  const token = localStorage?.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const SubCompanyId = localStorage?.getItem("SubCompanyId");
  const mainCompanyId= localStorage.getItem("mainCompanyId")
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const perPageRecord = localStorage.getItem("perPageRecord")
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [singleRecord, setSingleRecord] = useState();
  const [activeTab, setActiveTab] = useState(3);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [localizedStatus, setLocalizedStatus] = useState("");
  const [sendInvitationButtonLoading, setSendInvitationButtonLoading] =
    useState(false);
  const [loadingRow, setLoadingRow] = useState(null);
  const [totalRecord, setTotalRecord] = useState(0);
  const containerMediaQueryStyle = {
    width: "100%",
    margin: "0",
  };
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    handleAllBusinessPartnersGet(tabIndex);
  };

  const containerStyle = {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: ishbrews === "he" ? `${sidebarWidth}px` : "",
    marginLeft: ishbrews === "he" ? "" : `${sidebarWidth}px`,
  };
  const handleSelectedRowsChange = (rows) => {
    if (rows.allSelected) {
      setSelectedRows(rows.selectedRows);
    } else {
      setSelectedRows(rows.selectedRows);
    }
  };
  const sendInvitationBusinessPartners = async () => {
    const array = [];
    try {
      for (const selectData of selectedRows) {
        console.log("selectData", selectData);
        
        const object = {
          Email: selectData?.email ? selectData?.email : "",
          Vatid: selectData?.vatId,
          Lang: ishbrews === "he" ? 2 : 1,
          SubCompanyId: selectData?.subCompanyId,
          Businesspartner: selectData?.businesspartnerName
        };
        array.push(object);
      }
      if (token) {
        setSendInvitationButtonLoading(true);
        const response = await Api.businessPartnersSendEmail(array,mainCompanyId, token);
        if (response) {
          setSendInvitationButtonLoading(false);
          setToggleClearRows((prev) => !prev);
          setSelectedRows([]);
          const results = response?.emailResponses?.map((item) => item.result);
          if (results.every((result) => result === true)) {
            toast.success(t("toastMessage.allInvitations"));
          } else if (results.every((result) => result === false)) {
            toast.error(t("toastMessage.allInvitationsFailed"));
          } else {
            toast.error(t("toastMessage.someInvitationsFailed"));
          }
          getBusinessPartner(status, pageNumber, pagePerRecord);
        }
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
        setTableLoading(false);
      }
    } catch (e) {
      if (e?.status === 401) {
        if (refreshToken) {
          await Api.refreshToken({
            authenticationToken: refreshToken,
          })
            .then(async (res) => {
              localStorage.setItem("accessToken", res?.accessToken);
              localStorage.setItem("refreshToken", res?.refreshToken);
              const expiredSecond = await TokenExpired.getExpiredMinutes(
                res?.RefreshTokenExpiredTime
              );
              setTokenExpired(expiredSecond);
              const response = await Api.businessPartnersSendEmail(
                array,
                mainCompanyId,
                res?.accessToken
              );
              if (response) {
                setSendInvitationButtonLoading(false);
                setToggleClearRows((prev) => !prev);
                setSelectedRows([]);
                const results = response?.emailResponses?.map((item) => item.result);
                if (results.every((result) => result === true)) {
                  toast.success(t("toastMessage.allInvitations"));
                } else if (results.every((result) => result === false)) {
                  toast.error(t("toastMessage.allInvitationsFailed"));
                } else {
                  toast.error(t("toastMessage.someInvitationsFailed"));
                }
                getBusinessPartner(status, pageNumber, pagePerRecord);
              }
            })
            .catch((err) => {
              if (err?.status === 400) {
                setSendInvitationButtonLoading(false);
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            });
        } else {
          setSendInvitationButtonLoading(false);
          toast.error(t("token.authentication-message"));
          history.push("/");
        }
      } else {
        setSendInvitationButtonLoading(false);
        toast.error(e?.message ?? e);
      }
    }
  };
  const getBusinessPartner = async (filterType, pageNumber, pageSize) => {
    try {
      if (token) {
        setSortedBusinessPartnersValue([]);
        setBusinessPartnersValue([]);
        // setFilteredBusinessPartnersValue([])
        setTableLoading(true);
        const lang = ishbrews === "he" ? 2 : 1;
        const subIdCheck = subId ? subId : SubCompanyId;
        const response = await Api.getBusinessPartnersByFilter(
          filterType,
          subIdCheck,
          pageNumber,
          pageSize,
          lang,
          token
        );
        console.log("response", response);
        
        if (response) {
          setBusinessPartnersValue(response.businessPartnerProp);
          setSortedBusinessPartnersValue(response.businessPartnerProp);
          setFilteredBusinessPartnersValue(response.businessPartnerProp)
          setTotalRecord(response.totalResults);
          localStorage.setItem("BusinessPartnerRecord", response.totalResults);
          if (filterType === 3) {
            setBusinessPartner(response.totalResults);
          }
          setTableLoading(false);
        }
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
        setTableLoading(false);
      }
    } catch (e) {
      if (e?.status === 401) {
        if (refreshToken) {
          await Api.refreshToken({
            authenticationToken: refreshToken,
          })
            .then(async (res) => {
              localStorage.setItem("accessToken", res?.accessToken);
              localStorage.setItem("refreshToken", res?.refreshToken);
              const expiredSecond = await TokenExpired.getExpiredMinutes(
                res?.RefreshTokenExpiredTime
              );
              console.log("expiredSecond",expiredSecond);
              
              setTokenExpired(expiredSecond);
              const lang = ishbrews === "he" ? 2 : 1;
              const subIdCheck = subId ? subId : SubCompanyId;

              await Api.getBusinessPartnersByFilter(
                filterType,
                subIdCheck,
                pageNumber,
                pageSize,
                lang,
                res?.accessToken
              )
                .then((res) => {
                  if (res) {
                    setBusinessPartnersValue(res.businessPartnerProp);
                    setSortedBusinessPartnersValue(res.businessPartnerProp);
                    setFilteredBusinessPartnersValue(res.businessPartnerProp)
                    setTotalRecord(res.totalResults);
                    if (filterType === 3) {
                      setBusinessPartner(res.totalResults);
                    }
                    localStorage.setItem(
                      "BusinessPartnerRecord",
                      res.totalResults
                    );
                    setTableLoading(false);
                  } else {
                    setTableLoading(false);
                  }
                })
                .catch((err) => {
                  toast.error(err?.message ?? err);
                  setTableLoading(false);
                });
            })
            .catch((err) => {
              if (err?.status === 400) {
                setTableLoading(false);
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            });
        } else {
          setTableLoading(false);
          toast.error(t("token.authentication-message"));
          history.push("/");
        }
      } else {
        setTableLoading(false);
        toast.error(e?.message ?? e);
      }
    }
  };

  const handleSort = async (column, direction) => {
    const sortedData = [...businessPartnersValue].sort((a, b) => {
      if (a[column.key] < b[column.key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[column.key] > b[column.key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSortedBusinessPartnersValue(sortedData);
  };

  const handleAllBusinessPartnersGet = async (value) => {
    try {
      setTableLoading(true);
      await getBusinessPartner(value, pageNumber, perPageRecord ? perPageRecord : pageSize);
      setStatus(value);
    } catch (e) {
      console.log("Error fetching data:", e);
    } finally {
      setTableLoading(false);
    }
  };
  const handlePageChange = (page, perPage) => {
    getBusinessPartner(status, page, perPage);
    setPageNumber(page);
    setPagePerRecord(perPage);
    localStorage.setItem("perPageRecord", perPage)
  };

  const filterData = () => {
    if (search) {
      const filtered = businessPartnersValue.filter((partner) =>
        partner.businesspartnerName.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredBusinessPartnersValue(filtered);
    } else {
      setFilteredBusinessPartnersValue(sortedBusinessPartnersValue);
    }
  };

  const singleInvitationSent = async (record) => {
    try {
      if (token) {
        // setSendInvitationButtonLoading(true);
        const response = await Api.businessPartnersSendEmail(record,mainCompanyId, token);
        if (response) {
          // setSendInvitationButtonLoading(false);
          setToggleClearRows((prev) => !prev);
          setSelectedRows([]);
          toast.success(t("toastMessage.Emailsentsuccessfully"));
          setLoadingRow(null);
          getBusinessPartner(status, pageNumber, pageSize);
        }
      } else {
        toast.error(t("token.authentication-message"));
        history.push("/");
        setTableLoading(false);
      }
    } catch (e) {
      if (e?.status === 401) {
        if (refreshToken) {
          await Api.refreshToken({
            authenticationToken: refreshToken,
          })
            .then(async (res) => {
              localStorage.setItem("accessToken", res?.accessToken);
              localStorage.setItem("refreshToken", res?.refreshToken);
              const expiredSecond = await TokenExpired.getExpiredMinutes(
                res?.RefreshTokenExpiredTime
              );
              setTokenExpired(expiredSecond);
              const response = await Api.businessPartnersSendEmail(
                record,
                mainCompanyId,
                res?.accessToken
              );
              if (response) {
                toast.success(t("toastMessage.Emailsentsuccessfully"));
                setToggleClearRows((prev) => !prev);
                setSelectedRows([]);
                setLoadingRow(null);
                getBusinessPartner(status, pageNumber, pageSize);
              }
            })
            .catch((err) => {
              if (err?.status === 400) {
                toast.error(t("token.authentication-message"));
                history.push("/");
              }
            });
        } else {
          toast.error(t("token.authentication-message"));
          history.push("/");
        }
      } else {
        toast.error(e?.message ?? e);
        setLoadingRow(null);
      }
    }
  };
  const handleInvite = async (data, index) => {
    try {
      setSingleRecord(data);
      setLoadingRow(index);
      const array = [];
      if (data.email) {
        const object = {
          Email: data?.email,
          Vatid: data?.vatId,
          Lang: ishbrews === "he" ? 2 : 1,
          SubCompanyId: data?.subCompanyId,
          Businesspartner: data?.businesspartnerName
        };
        array.push(object);
      } else {
        setModalShow(true);
        if (email) {
          const object = {
            Email: email,
            Vatid: data?.vatId,
            Lang: ishbrews === "he" ? 2 : 1,
            SubCompanyId: data?.subCompanyId,
            Businesspartner: data?.businesspartnerName
          };
          array.push(object);
        }
      }
      if (array.length) {
        singleInvitationSent(array);

        console.log("array", array);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getBusinessPartner(status, pageNumber, perPageRecord ? perPageRecord : pageSize);
    setToggleClearRows(true);
  }, [ishbrews, subId, SubCompanyId, refresh]);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  useEffect(() => {
    const baseColumns = [
      {
        name: (
          <span
            className={
              ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
            }
          >
            {ishbrews === "he"
              ? "שותפים עסקיים"
              : t("datatable.businesspartner")}
          </span>
        ),
        key: "businesspartnerName",
        selector: (row) => (
          <span className="text-start column-text">
            {row?.businesspartnerName}
          </span>
        ),
        sortable: true,
        width: "250px",
      },
      {
        name: (
          <span
            className={
              ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
            }
          >
            {ishbrews === "he" ? "מזהה עוסק" : t("datatable.vatID")}
          </span>
        ),
        key: "vatId",
        selector: (row) => (
          <span className="text-start column-text">{row?.vatId}</span>
        ),
        sortable: true,
        width: "120px",
      },
      {
        name: (
          <span
            className={
              ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
            }
          >
            {ishbrews === "he" ? "מספר מסמכים" : t("datatable.docAmount")}
          </span>
        ),
        key: "docAmount",
        selector: (row) => (
          <span className="text-start column-text">{row?.docAmount}</span>
        ),
        sortable: true,
        width: "120px",
      },
      {
        name: (
          <span
            className={
              ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
            }
          >
            {ishbrews === "he" ? "אימייל" : t("forgotPassword.part21")}
          </span>
        ),
        key: "email",
        selector: (row) => (
          <span className="text-start column-text">
            {row?.email ? row?.email : "-"}
          </span>
        ),
        sortable: true,
        width: "250px",
      },
      {
        name: (
          <span
            className={
              ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
            }
          >
            {" "}
            {ishbrews === "he" ? "סטטוס" : t("datatable.Status")}
          </span>
        ),
        key: "status",
        selector: (row) => (
          <div className="me-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id={`tooltip-${row.status}`}
                  dir={ishbrews === "he" ? "rtl" : "ltr"}
                  className={
                    ishbrews === "he" ? "tooltip-right" : "tooltip-left"
                  }
                >
                  {row?.status == "Connected to Uninet"
                    ? t("businessPartners.connectTooltipText")
                    : row?.status == "Still not connected"
                    ? t("businessPartners.stillNotConnectTooltipText")
                    : row?.status == "Waiting for invitation"
                    ? t("businessPartners.waitingForInvitationToltip")
                    : t("businessPartners.missingEmailDetailsTooltip")}
                  {/* {row.status} */}
                </Tooltip>
              }
            >
              <span
                className={`text-start column-text ${
                  row?.status == "Connected to Uninet"
                    ? "connected-text"
                    : row?.status == "Still not connected"
                    ? "not-connected-text"
                    : row?.status == "Waiting for invitation"
                    ? "missing-text"
                    : row?.status == "עדיין לא מחובר ליונינט"
                    ? "not-connected-text"
                    : row?.status == "מחובר ליונינט"
                    ? "connected-text"
                    : row?.status == "מחכה להזמנה"
                    ? "missing-text"
                    : "not-valid-text"
                } `}
              >
                {row?.status}
              </span>
            </OverlayTrigger>
          </div>
        ),
        sortable: true,
        // width: "200px",
      },
      {
        name: (
          <span
            className={
              ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
            }
          >
            {ishbrews === "he" ? "פעולות" : t("datatable.actions")}
          </span>
        ),
        selector: (row, index) => (
          <div>
            {row?.actions?.type === 3 ? (
              <span className="text-start column-text">
                {row?.actions?.label}
              </span>
            ) : row?.actions?.type === 1 ? (
              <button
                className=" bg-[#219EBC] rounded-md border border-solid border-[#219EBC] outline-none px-[24px] py-[6px] text-white  transition duration-300 ease-in hover:bg-[#03506F] hover:border-[#03506F]"
                onClick={() => handleInvite(row, index)}
                disabled={loadingRow === index || selectedRows.length > 0}
              >
                {loadingRow == index ? (
                  <>
                    {ishbrews == "he" ? (
                      <>
                        <span className="">
                          ...{t("signin.Loading")}
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className=" mr-1"
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="">
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className=" mr-1"
                          />
                          {t("signin.Loading")}...
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>{row?.actions?.label}</>
                )}
              </button>
            ) : row?.actions?.type === 2 ? (
              <span className="text-start column-text">
                {row?.actions?.label}
              </span>
            ) : (
              <button
                className=" bg-[#219EBC] rounded-md border border-solid border-[#219EBC] outline-none px-[24px] py-[6px] text-white  transition duration-300 ease-in hover:bg-[#03506F] hover:border-[#03506F]"
                onClick={() => handleInvite(row)}
                disabled={loadingRow === row?.vatId}
              >
                {/* {loadingRow == row?.vatId ? (
                  <>
                    {ishbrews == "he" ? (
                      <>
                        <span className="">
                          ...{t("signin.Loading")}
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className=" mr-1"
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="">
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className=" mr-1"
                          />
                          {t("signin.Loading")}...
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )} */}
                {row?.actions?.label}
              </button>
            )}
          </div>
        ),
        width: "250px",
      },
    ];

    if (status === 3) {
      const updatedColumns = [
        ...baseColumns.slice(0, 2),
        {
          name: (
            <span
              className={
                ishbrews === "he" ? "dataTable-text-right" : "dataTable-text"
              }
            >
              {" "}
              {ishbrews === "he" ? "סוג שותף עסקי" : t("datatable.partnerType")}
            </span>
          ),
          key: "type",
          selector: (row) => (
            <span className="text-start column-text">{row?.type}</span>
          ),
          sortable: true,
          width: "130px",
        },
        ...baseColumns.slice(2),
      ];

      setColumns(updatedColumns);
    } else {
      setColumns(baseColumns);
    }
  }, [status, loadingRow, ishbrews, selectedRows]);
  useEffect(() => {
    filterData();
  }, [search, sortedBusinessPartnersValue]);
  useEffect(() => {
    const filteredData = businessPartnersValue.filter((item) => {
      if (!localizedStatus) return true;
      return item.status === localizedStatus;
    });
    setFilteredBusinessPartnersValue(filteredData);
  }, [selectedStatus, businessPartnersValue, localizedStatus]);
 
  const getLocalizedStatus = (status, ishbrews) => {
    const statusMap = {
      "Connected to Uninet": "מחובר ליונינט",
      "Still not connected": "עדיין לא מחובר ליונינט",
      "Missing email details": "פרטיי אי מייל חסרים",
      "Waiting for invitation": "מחכה להזמנה",
    };
    return ishbrews === "he" ? statusMap[status] || status : Object.keys(statusMap).find(key => statusMap[key] === status) || status;
  };
  useEffect(() => {
    setLocalizedStatus(getLocalizedStatus(selectedStatus, ishbrews));
  }, [localizedStatus, ishbrews, selectedStatus]);
  
  return (
    <div
      style={isScreenSmall ? containerMediaQueryStyle : containerStyle}
      className="console-responsive bg-white min-h-screen rounded-md px-[10px] py-[10px] mt-2 md:mt-0"
    >
      <div
        className={
          ishbrews === "he"
            ? "custom-tabs custom-table-listing-card mr-[10px] nav nav-tabs max-w-[400px] md:mr-0 w-full ml-auto hebrew flex-row-reverse "
            : "custom-tabs custom-table-listing mr-auto nav fex-row nav-tabs max-w-[400px]  w-full"
        }
      >
        <div className="nav-item">
          <button
            className={`nav-link ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabClick(3)}
            disabled={tableLoading || activeTab == 3}
          >
            {t("businessPartners.all")}
          </button>
        </div>
        <div className="nav-item">
          <button
            className={`nav-link ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClick(2)}
            disabled={tableLoading || activeTab == 2}
          >
            {t("businessPartners.client")}
          </button>
        </div>
        <div className="nav-item">
          <button
            className={`nav-link ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
            disabled={tableLoading || activeTab == 1}
          >
            {t("businessPartners.suppliers")}
          </button>
        </div>
      </div>

      <div
        className={
          ishbrews === "he"
            ? " mb-[20px] md:mb-[16px] mt-[20px] mr-[10px]  md:mt-[24px] md:mr-0 flex gap-3 flex-col md:flex-row-reverse  md:gap-0 justify-between items-center"
            : " mb-[20px] md:mb-[16px] mt-[20px] mr-[10px]  md:mt-[24px] md:mr-0 flex gap-3 flex-col md:flex-row md:gap-0 justify-between items-center"
        }
      >
        <div
          className={
            ishbrews === "he"
              ? "relative search-icon-hebrew max-w-full  md:max-w-[300px] w-full"
              : "relative search-icon max-w-full md:max-w-[300px] w-full"
          }
        >
          <input
            type="text"
            placeholder={t("businessPartners.searchPartner")}
            className={
              ishbrews === "he"
                ? "rounded-[10px] border border-solid border-[#F9F9F9] text-right pr-[28px] text-[14px] bg-[#F0F0F0] py-[10px] pl-[14px] w-full mr-[6px]"
                : "rounded-[10px] border border-solid border-[#F9F9F9] text-[14px] bg-[#F0F0F0] py-[10px] pl-[28px] pr-[14px]  w-full ml-[6px]"
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div
          className={
            ishbrews === "he"
              ? "flex gap-[10px] items-center flex-wrap flex-row-reverse"
              : "flex gap-[10px] flex-row items-center flex-wrap"
          }
        >
           <select
    className={
      ishbrews === "he"
        ? "form-select-select-status bg-[#F0F0F0] border text-[#252F4A] text-[15px] font-[450] border-solid border-[#F9F9F9] rounded-[10px] py-[10px] pr-[14px] text-right"
        : "form-select-select bg-[#F0F0F0] border text-[#252F4A] text-[15px] font-[450] border-solid border-[#F9F9F9] rounded-[10px] py-[10px] pr-[38px] pl-[14px]"
    }
    value={localizedStatus} // Set the value to localizedStatus
    onChange={handleStatusChange}
  >
    <option value="" key="" style={ishbrews === "he" ? { paddingRight: "15px" } : {}}>
      {t("businessPartners.status")}
    </option>
    <option
      value={getLocalizedStatus("Connected to Uninet", ishbrews)}
      style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
    >
      {t("businessPartners.connectedToUninet")}
    </option>
    <option
      value={getLocalizedStatus("Still not connected", ishbrews)}
      style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
    >
      {t("businessPartners.stillNotConnected")}
    </option>
    <option
      value={getLocalizedStatus("Missing email details", ishbrews)}
      style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
    >
      {t("businessPartners.missingEmailDetails")}
    </option>
    <option
      value={getLocalizedStatus("Waiting for invitation", ishbrews)}
      style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
    >
      {t("businessPartners.waitingForInvitation")}
    </option>
  </select>
          {/* <select
            className={
              ishbrews === "he"
                ? "form-select-select-status bg-[#F0F0F0] border text-[#252F4A] text-[15px] font-[450] border-solid border-[#F9F9F9] rounded-[10px] py-[10px] pr-[14px] text-right"
                : "form-select-select bg-[#F0F0F0] border text-[#252F4A] text-[15px] font-[450] border-solid border-[#F9F9F9] rounded-[10px] py-[10px] pr-[38px] pl-[14px]"
            }
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <option
              value=""
              key=""
              style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
            >
              {t("businessPartners.status")}
            </option>
            <option
            value={getOptionValue("connectedToUninet")}
             style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
            >
              {t("businessPartners.connectedToUninet")}
            </option>
            <option
            value={getOptionValue("stillNotConnected")}
            style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
            >
              {t("businessPartners.stillNotConnected")}
            </option>
            <option
            value={getOptionValue("missingEmailDetails")}
              style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
            >
              {t("businessPartners.missingEmailDetails")}
            </option>
            <option
            value={getOptionValue("waitingForInvitation")}
              style={ishbrews === "he" ? { paddingRight: "15px" } : {}}
            >
              {t("businessPartners.waitingForInvitation")}
            </option>
          </select> */}

          <button
            className={` border-solid text-white rounded-[10px] py-[10px] px-[20px] transition duration-300 ease-in 
             ${
               selectedRows.length <= 0
                 ? "bg-[#73d1e9] border border-[#73d1e9]"
                 : "hover:bg-[#03506F] hover:border-[#03506F] bg-[#219EBC] border border-[#219EBC]"
             }`}
            onClick={sendInvitationBusinessPartners}
            disabled={selectedRows.length <= 0}
          >
            {sendInvitationButtonLoading ? (
              <>
                {ishbrews == "he" ? (
                  <>
                    <span className="">
                      ...{t("signin.Loading")}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className=" mr-1"
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <span className="">
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className=" mr-1"
                      />
                      {t("signin.Loading")}...
                    </span>
                  </>
                )}
              </>
            ) : (
              <>{t("businessPartners.sendInvitation")}</>
            )}
          </button>
        </div>
      </div>
      <Datatables
        businessPartnersValue={filteredBusinessPartnersValue}
        loading={tableLoading}
        columns={columns}
        handleSort={handleSort}
        handleSelectedRowsChange={handleSelectedRowsChange}
        toggledClearRows={toggledClearRows}
      />
      <div
        className={
          ishbrews === "he"
            ? "flex justify-start items-end mt-1 mr-2"
            : "flex justify-end items-end mt-1 mr-2"
        }
      >
        {!tableLoading && (
          <Pagination
            onPageChange={handlePageChange}
            currentPage={pageNumber}
            totalRecod={totalRecord}
            pagePerRecord={perPageRecord ? perPageRecord : pageSize}
            length1={filteredBusinessPartnersValue.length}
          />
        )}
      </div>
      <InviteModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        isLoading={isLoading}
        setEmail={setEmail}
        email={email}
        setEmailError={setEmailError}
        emailError={emailError}
        setIsLoading={setIsLoading}
        handleInvite={handleInvite}
        singleRecord={singleRecord}
        setLoadingRow={setLoadingRow}
      />
    </div>
  );
};

export default BusinessPartners;
