import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {AuthUserContext} from "../../context"
export default function PrivateRoute(props) {
  const Component = props.component;
  const route = props.route;
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();
  const { setUrl } = useContext(AuthUserContext);
  useEffect(() => {
    const currentUrl = window.location.href;
    setUrl(currentUrl)
    const user = localStorage?.getItem("accessToken");
    if (user) {
      React.startTransition(() => {
        setAuthenticated(true);
      });
    } else {
      React.startTransition(() => {
        setAuthenticated(false);
        history.push("/");
      });
    }
  }, [history]);

  return authenticated ? (
    <>
      <Component route={route} />
    </>
  ) : null;
}
