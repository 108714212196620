import { config } from "../configs";
import Service from "./service";
const Api = {
  SignInUser: async (data) => {
    try {
      return await Service.post(
        `${config.BaseURL}/api/Login/LoginWithEmailPassword`,
        {
          data,
        }
      );
    } catch (error) {
      throw error;
    }
  },
  SignInWithGoogle: async (data) => {
    try {
      return await Service.post(`${config.BaseURL}/api/Login/GoogleSignIn`, {
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  SignUpUser: async (data) => {
    try {
      return await Service.post(`${config.BaseURL}/api/Register/Register`, {
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  SignUPWithOtp: async (data) => {
    try {
      return await Service.post(
        `${config.BaseURL}/api/Register/RegisterWithOtp`,
        {
          data,
        }
      );
    } catch (error) {
      throw error;
    }
  },

  SecondQuestionnaire: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Register/RegisterBusinessToUser`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },
  forgotPassword: async (data) => {
    try {
      return await Service.post(`${config.BaseURL}/api/Login/ForgotPassword`, {
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (data) => {
    try {
      return await Service.post(`${config.BaseURL}/api/Login/ResetPassword`, {
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  resentOTP: async (data) => {
    try {
      return await Service.post(`${config.BaseURL}/api/Register/ResentOtp`, {
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  getExternalSystem: async (lang, token) => {
    try {
      return await Service.get(
        `${config.BaseURL}/api/Register/GetExternalSystem?Lang=${lang}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },

  getWelcomeToUninet: async (lang, token) => {
    try {
      return await Service.get(
        `${config.BaseURL}/api/Register/GetWelcomeToUninet?Lang=${lang}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },

  getExternalCustomizedField: async (id, token) => {
    try {
      return await Service.get(
        `${config.BaseURL}/api/Register/GetExternalCustomizedFieldByExternaLSystemID?ExternalSystemId=${id}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  SaveExternalCustomizedExternalSystemId: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Register/SaveExternalCustomizedExternalSystemId`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },

  GetDigitalDocumentToApproveListByUser: async (token, type) => {
    try {
      return await Service.get(
        `${config.BaseURL}/api/UninetOutPutControllercs/GetDigitalDocumentToApproveListByUser?Typelist=${type}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },

  ApproveDoc: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/UninetOutPutControllercs/AproveDoc`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },
  RejectDoc: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/UninetOutPutControllercs/RejectDocument`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },
  ShowDigitalDocumentDetails: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/UninetOutPutControllercs/ShowDigitalDocumentDetails`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },
  refreshToken: async (data) => {
    try {
      return await Service.post(`${config.BaseURL}/api/Login/RefreshToken`, {
        data,
      });
    } catch (error) {
      throw error;
    }
  },

  inviteBusinessPartners: async (lang,subCopmanyIds, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Register/InviteBusinessPartners?lang=${lang}&subcompanyid=${subCopmanyIds}`,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  addexpenseType: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/UninetOutPutControllercs/AddexpenseType`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },

  verifyEmailLink: async (data) => {
    try {
      return await Service.post(
        `${config.BaseURL}/api/Register/VerifyEmailLink`,
        {
          data,
        }
      );
    } catch (error) {
      throw error;
    }
  },
  getActiveTab: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Register/GetActiveTab`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },

  ShowCurrentExternalSystemDetails: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Register/ShowCurrentExternalSystemDetails`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },

  ShowUserBillingDetails: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Billing/ShowUserBillingDetails`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },

  SaveBillingDetails: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Billing/Save`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },

  SaveFristTimeBillingMessage: async (data, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/Billing/ContinueFreeBilling`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },
  getBusinessPartnersByFilter: async (
    filterType,
    subCompanyId,
    pageNumber,
    pageSize,
    lang,
    token
  ) => {
    try {
      return await Service.get(
        `${config.BaseURL}/api/BusinessPartners/GetBusinessPartnersByFilter?filterType=${filterType}&subCopmanyId=${subCompanyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&lang=${lang}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  businessPartnersSendEmail: async (data,mainCompanyId, token) => {
    try {
      return await Service.postWithAuthentication({
        url: `${config.BaseURL}/api/BusinessPartners/SendEmail?&MainCompanyid=${mainCompanyId}`,
        token,
        data: data,
      });
    } catch (error) {
      throw error;
    }
  },
  shortVersionGetBusinessPartnersByFilter : async(subCompanyId, lang, token)=>{
    try {
      return await Service.get(
        `${config.BaseURL}/api/BusinessPartners/ShortVersionGetBusinessPartnersByFilter?filterType=4&subCopmanyId=${subCompanyId}&pageNumber=1&pageSize=10&lang=${lang}`,
        token
      );
    } catch (error) {
      throw error;
    }
  }
};
export default Api;
