import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "../services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { TokenExpired } from "../services/tokenExpired";
import { AuthUserContext } from "../context";
import CreditForm from "./addCard";

const Billing = ({ sidebarWidth, subId, refresh }) => {
  const { setTokenExpired } = useContext(AuthUserContext);
  let ishbrews = localStorage.getItem("i18nextLng");
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 992);

  const containerMediaQueryStyle = {
    width: "100%",
    margin: "0",
  };

  const containerStyle = {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: ishbrews === "he" ? `${sidebarWidth}px` : "",
    marginLeft: ishbrews === "he" ? "" : `${sidebarWidth}px`,
  };

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const [billingStatus, setBillingStatus] = useState(false);
  const [billingResponse, setBillingResponse] = useState();

  useEffect(() => {
    userBillingDetails();
    setCardDetails();
  }, [subId, refresh]);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const userBillingDetails = () => {
    const SubCompanyId = subId ? subId : localStorage.getItem("SubCompanyId");
    const BusinessId = localStorage?.getItem("businessId")
      ? localStorage?.getItem("businessId")
      : localStorage?.getItem("mainCompanyId");
    if (token) {
      setLoading(true);
      Api.ShowUserBillingDetails({ SubCompanyId, BusinessId }, token)
        .then(async (res) => {
          setCardDetails(res.creditCardHolders);
          setLoading(false);
        })
        .catch(async (e) => {
          if (e?.status === 401) {
            if (refreshToken) {
              await Api.refreshToken({
                authenticationToken: refreshToken,
              })
                .then(async (res) => {
                  localStorage.setItem("accessToken", res?.accessToken);
                  localStorage.setItem("refreshToken", res?.refreshToken);
                  const expiredSecond = await TokenExpired.getExpiredMinutes(
                    res?.RefreshTokenExpiredTime
                  );
                  setTokenExpired(expiredSecond);
                  await Api.ShowUserBillingDetails(
                    { SubCompanyId, BusinessId },
                    res?.accessToken
                  ).then(async (res) => {
                    setCardDetails(res.creditCardHolders);
                    setLoading(false);
                  });
                })
                .catch((err) => {
                  if (err?.status === 400) {
                    toast.error(t("token.authentication-message"));
                    history.push("/");
                  }
                });
            } else {
              toast.error(t("token.authentication-message"));
              history.push("/");
            }
          } else {
            setLoading(false);
            toast.error(e?.message ?? e);
          }
          if (e?.status === 400) {
            toast.error(t("token.authentication-message"));
            history.push("/");
          } else {
            toast.error(t("token.authentication-message"));
            history.push("/");
          }
        });
    } else {
      setLoading(false);
      toast.error(t("token.authentication-message"));
      history.push("/");
    }
  };
  const handlecredit = () => {
    setBillingStatus(false);
    userBillingDetails();
  };
  return (
    <div
      style={isScreenSmall ? containerMediaQueryStyle : containerStyle}
      className={
        ishbrews === "he" ? "container-fluid p-0 " : "container-fluid p-0 "
      }
    >
      <div className="content rounded-md py-3 md:py-4 px-3 md:px-4 m-3 ">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}

        {billingStatus && (
          <div
            id="custom-prompt"
            className="fixed flex items-center justify-center flex-col z-100 mx-2 md:mx-0 back-color"
          >
            <div className="bg-white rounded-lg shadow-lg max-w-[400px] p-5 ">
              <div
                className={
                  ishbrews === "he" ? "flex justify-start" : "flex justify-end"
                }
              ></div>
              <h1
                className={
                  "text-2xl text-center text-text-color mb-2 font-medium mb-4"
                }
                style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
              >
                {billingResponse.title}
              </h1>

              <p
                className={
                  ishbrews === "he"
                    ? "text-gray-800 text-right text-sm mb-4"
                    : "text-gray-800 text-sm mb-4"
                }
                style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
              >
                {billingResponse.content}
              </p>
              <div
                className={
                  ishbrews === "he" ? "flex justify-start" : "flex justify-end"
                }
              >
                <button
                  className=" bg-bg-secondary  flex gap-2 items-center text-white py-2 px-4 rounded-lg mr-2"
                  onClick={handlecredit}
                >
                  {billingResponse.buttonLabel}
                </button>
              </div>
            </div>
          </div>
        )}

        <div
          className={`w-3/4 creditCard max-w-full mx-auto bg-white shadow-md rounded-md p-3`}
        >
          <h2
            className="font-semibold text-2xl mb-[10px] text-center mt-1"
            style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
          >
            {t("billing.billingDetails")}
          </h2>
          <h4
            className="text-2xl mb-[10px] text-center mt-3"
            style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
          >
            {t("billing.noPayment")}
          </h4>
          <p
            className="text-lg text-gray-500 text-center mt-3"
            style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
          >
            {t("billing.content")}
          </p>
        </div>

        <div
          className={` w-3/4 creditCard max-w-full mx-auto bg-white shadow-md rounded-md p-3 mt-2 ${
            ishbrews === "he" ? "text-right rtl" : "text-left ltr"
          }`}
        >
          <h2 className="font-semibold text-2xl mb-[10px] text-center mt-1">
            {t("billing.paymentMethod")}
          </h2>

          <div
            className={`flex flex-wrap justify-center gap-4 p-3 ${
              ishbrews === "he" ? "flex-row-reverse" : "row"
            }`}
          >
            {cardDetails &&
              cardDetails.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-col p-2 border rounded-lg shadow-sm bg-gray-50 w-full sm:max-w-md`}
                  style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
                >
                  <div
                    className={`flex  p-1 ${
                      ishbrews === "he" ? "mr-3" : "ml-3"
                    }`}
                    style={{ direction: ishbrews === "he" ? "rtl" : "ltr" }}
                  >
                    <img
                      src={item.creditcardLogo}
                      alt="Mastercard"
                      height={"60px"}
                      width={"60px"}
                      className="m-2"
                    />
                    <div
                      dir={ishbrews === "he" ? "rtl" : "ltr"}
                      className={`${ishbrews === "he" ? "mr-3" : "ml-3"}`}
                    >
                      <p className="font-semibold">
                        {item.cardName}••••{item.last4Digits}
                      </p>
                      <p className="text-sm text-gray-500">
                        Expired : {item.expirationDate}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            {cardDetails && cardDetails.length == 0 && (
              <div
                className={`flex flex-col justify-center p-4 border rounded-lg shadow-sm bg-gray-50 w-full sm:max-w-md `}
              >
                <button className="text-black-800">
                  {" "}
                  {t("credit-debit.nocard")}
                </button>
              </div>
            )}
          </div>
        </div>

        <div
          className={` w-3/4 creditCard max-w-full mx-auto bg-white shadow-md rounded-md mt-2 p-3`}
        >
          <CreditForm
            setLoading={setLoading}
            setBillingResponse={setBillingResponse}
            setBillingStatus={setBillingStatus}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
};

export default Billing;
