import React, { useEffect, useState } from "react";
import TabsInfo from "./tabsInfo";
import ShowPdf from "./showPdf";
import DocData from "./docData";
import InviteBusinessPartner from "./inviteBusinessPartner";

const ExpenseManagment = ({
  sidebarWidth,
  setIsLoginSuccess,
  setTotalDocs,
  subId,
  totalDocs,
  setSubCompanyList,
  refresh,
  setRefresh,
  setIsBusinessPartner,
  isBusinessPartner,
  setIsBusinessPartnerModal,
  isBusinessPartnerModal

}) => {
  const [formValues, setFormValues] = useState({});
  const [rejectData, setRejectData] = useState({});
  const [isPDFLoaded, setIsPDFLoaded] = useState(true);
  const [pdfFile, setPdfFile] = useState([]);
  const [isRejected, setIsRejected] = useState(false);
  const [isEntered, setIsEntered] = useState(false);
  const [activeKey, setActiveKey] = useState("Inbox");
  const [rejectedListByUser, setRejectedListByUser] = useState([]);
  const [clientId, setClientId] = useState();
  const [businessPartner, setBusinessPartner] = useState([]);
  const [loading, setLoading] = useState(false);
  let ishbrews = localStorage.getItem("i18nextLng");
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerMediaQueryStyle = {
    width: "100%",
    margin: "0",
  };

  const containerStyle = {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: ishbrews === "he" ? `${sidebarWidth}px` : "",
    marginLeft: ishbrews === "he" ? "" : `${sidebarWidth}px`,
  };

  return (
    <>
      <div
        style={isScreenSmall ? containerMediaQueryStyle : containerStyle}
        className={
          ishbrews === "he"
            ? " flex w-full flex-col md:flex-row-reverse gap-2  bg-primary-color h-full mb-0 "
            : " flex w-full flex-col md:flex-row gap-2 bg-primary-color h-full mb-0 "
        }
      >
        {isPDFLoaded && pdfFile && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}

        <TabsInfo
          setPdfFile={setPdfFile}
          setFormValues={setFormValues}
          setRejectData={setRejectData}
          setIsPDFLoaded={setIsPDFLoaded}
          isEntered={isEntered}
          setIsEntered={setIsEntered}
          isRejected={isRejected}
          setIsRejected={setIsRejected}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          setRejectedListByUser={setRejectedListByUser}
          rejectedListByUser={rejectedListByUser}
          setIsLoginSuccess={setIsLoginSuccess}
          setTotalDocs={setTotalDocs}
          subId={subId}
          totalDocs={totalDocs}
          setSubCompanyList={setSubCompanyList}
          refresh={refresh}
          setClientId={setClientId}
          setRefresh={setRefresh}
          businessPartner={businessPartner}
          setBusinessPartner={setBusinessPartner}
          setIsBusinessPartner={setIsBusinessPartner}
          isBusinessPartner={isBusinessPartner}
          setIsBusinessPartnerModal={setIsBusinessPartnerModal}
        />
        {/* {isBusinessPartner ? (
          <>
            
          </>
        ) : (
          
        )} */}
        <ShowPdf
          pdfFile={pdfFile}
          setIsPDFLoaded={setIsPDFLoaded}
          isPDFLoaded={isPDFLoaded}
        />

        <DocData
          formValues={formValues}
          setFormValues={setFormValues}
          rejectData={rejectData}
          setIsRejected={setIsRejected}
          setIsEntered={setIsEntered}
          activeKey={activeKey}
          rejectedListByUser={rejectedListByUser}
          setRejectedListByUser={setRejectedListByUser}
          clientId={clientId}
          setPdfFile={setPdfFile}
        />

        <InviteBusinessPartner
          businessPartner={businessPartner}
          loading={loading}
          sidebarWidth={sidebarWidth}
          isBusinessPartner={isBusinessPartner}
          setIsBusinessPartner={setIsBusinessPartner}
          setIsBusinessPartnerModal={setIsBusinessPartnerModal}
          isBusinessPartnerModal={isBusinessPartnerModal}
        />
      </div>
    </>
  );
};

export default ExpenseManagment;
