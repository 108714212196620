import React, { useState, useContext, useEffect, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import SideBarConsole from "../../components/sideBarConsole";
import { useTranslation } from "react-i18next";
import en from "../../assets/images/en.svg";
import he from "../../assets/images/he.svg";
import NeedHelp from "../../components/needHelp";
import { AuthUserContext } from "../../context";
import LanguageConvert from "../../components/languageConvert";

const MainConsole = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const sideBarRef = useRef()
  const { setTaostValue } = useContext(AuthUserContext);

  let ishbrews = localStorage.getItem("i18nextLng");
  const { setTokenExpired } = useContext(AuthUserContext);

  let { path, url } = useRouteMatch();
  const [sidebarWidth, setSidebarWidth] = useState(100)

  useEffect(() => {
    setSidebarWidth(sideBarRef?.current?.offsetWidth)
  }, [changeLanguage])

  useEffect(() => {
    if (localStorage.getItem("userFlag") == "true") {
      setTokenExpired(parseInt(localStorage.getItem("refershTokenExpired")));
    }
  }, [])


  return (
    <>
      {/* <Link to={`${url}/profile`}>Profile</Link> */}
      <div className="bg-bg-linear min-h-screen flex h-full">
        <SideBarConsole sidebarWidth={sidebarWidth} sideBarRef={sideBarRef} />
      </div>

      <LanguageConvert bgKey={true}/>
      <NeedHelp />
    </>
  );
};

export default MainConsole;
