import ForgetPassword from "../../views/auth/forgetPassword";
import ResetPassword from "../../views/auth/resetPassword";
import Signin from "../../views/auth/signin";
import Signup from "../../views/auth/signup";
import VerifyEmail from "../../views/auth/verifyEmail";

import {
  forgetPassword,
  resetPassword,
  signIn,
  signUp,
  verifyEmail,
} from "../pathName";

const authRoutes = [
  {
    title: "SignIn",
    component: Signin,
    path: signIn,
  },
  {
    title: "SignUp",
    component: Signup,
    path: signUp,
  },
  {
    title: "Forget Password",
    component: ForgetPassword,
    path: forgetPassword,
  },
  {
    title: "Verify Email",
    component: VerifyEmail,
    path: verifyEmail,
  },
  {
    title: "Reset Password",
    component: ResetPassword,
    path: resetPassword,
  },
];

export default authRoutes;
