import Questionnaire from "../../views/questionnaire";
import WelcomeScreen from "../../views/welcomeScreen";
import MainConsole from "../../views/mainConsole";
import { questionnaire, welcomeScreen, mainConsole } from "../pathName";

export const PrivateRoutes = [
  {
    title: "Questionnaire",
    component: Questionnaire,
    path: questionnaire,
  },
  {
    title: "Welcome",
    component: WelcomeScreen,
    path: welcomeScreen,
  },
  {
    title: "Main Console",
    component: MainConsole,
    path: mainConsole,
  }
];
